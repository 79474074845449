import React from "react";
//Components
import Template4ProductAssets from "./Template4ProductAssets";

export const Template4ProductContent = ({
  productTitle,
  productSKU,
  productPrice,
  productImage,
  buyNowURL,
  productDesc,
  data,
  setEnquiry,
  productModel,
  currencyCode,
  settings
}) => {
  return (
    <>
      <div className="temp1-prod-content--wrapper">
        <div className="temp1-prod-content--inner-wrapper">
          <section className="temp1-prod-content__top-section">
            <div className="temp1-prod-display__image-box">
              {productModel != "" && productModel != null ? (
                productModel != "" &&
                productModel != null && (
                  <model-viewer
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "1rem",
                    }}
                    alt="Source 3D Model"
                    src={productModel}
                    ar
                    ar-modes="scene-viewer quick-look webxr"
                    environment-image=""
                    poster=""
                    shadow-intensity="1"
                    camera-controls
                    touch-action="pan-y"
                    xr-environment
                  ></model-viewer>
                )
              ) : (
                <img className="temp1-prod-display__image" src={productImage} />
              )}

              {/* {productModel=="" || productModel==null || (<img className="temp1-prod-display__image" src={productImage} /> )} */}
              {/* <img className="temp1-prod-display__image" src={productImage} /> */}
            </div>
            <div className="temp1-prod-display__details-box">
              <h1 className="temp1-prod__title">{productTitle}</h1>
              <h4 className="temp1-prod__sku">
                {(productSKU != "") & (productSKU != null) ? (
                  <span>SKU : {productSKU}</span>
                ) : null}
              </h4>
              <span className="temp1-prod__price">
                {productPrice} <span className="fs-6">{currencyCode}</span>
              </span>
              <div className="custom-button__box">
                {typeof buyNowURL !== "undefined" &&
                buyNowURL !== "" &&
                buyNowURL !== null ? (
                  <a
                    className="product-buynow-btn"
                    href={buyNowURL}
                    target="_blank"
                  >
                    <button
                      id="buy-now-button"
                      className="custom-button custom-button--primary custom-button--border-primary  custom-button--px-2-py-1 custom-button--color-white "
                    >
                      BUY NOW
                    </button>
                  </a>
                ) : null}

                <button
                  id="enquire-product-button"
                  className="custom-button custom-button--border-primary custom-button--px-2-py-1 custom-button--color-dark custom-button--transparent "
                  onClick={() => {
                    setEnquiry(true);
                  }}
                >
                  ENQUIRE
                </button>
              </div>
            </div>
          </section>
          <section className="temp1-prod-content__bottom-section">
            {/* <h2 className="temp1-prod__subtitle">DESCRIPTION</h2>
    <div className="temp1-prod__desc" dangerouslySetInnerHTML={{__html:productDesc}}></div> */}
            <div style={{ width: "100%" }}>
              <Template4ProductAssets data={data} settings={settings}/>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
