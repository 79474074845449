import React from "react";
import { MdOutlineLock } from "react-icons/md";

import wrongPassword from "../../assets/wrongPassword.png";
const PasswordDialog = ({ passwordSubmitFN, isPasswordFalse, visibility }) => {
  const submitPswd = (e) => {
    e.preventDefault();
    passwordSubmitFN(e.target.password.value);
    // console.log(e.target.password.value);
    // console.log("pass",e.password.value);
    e.target.password.value = "";
  };

  return (
    <div>
      <div className="pass-sec">
        <div className="pass-container">
          <div className="pass-body">
            <div className="pass-header">
              {!isPasswordFalse && (
                <span className="pass-icon">
                  <MdOutlineLock />
                </span>
              )}

              {isPasswordFalse && (
                <span className="wrong-password-icon">
                  <img src={wrongPassword} />
                </span>
              )}


              <p>{visibility==='private'?'Please enter your invited email address to access.':'Please enter the password to access.'}</p>

              <form onSubmit={(e) => submitPswd(e)}>
                <div>
                  <input
                    type={visibility==='private'?'text':'password'}
                    className={`form-control password-input ${
                      isPasswordFalse ? "collection-auth-error" : ""
                    }`}
                    name="password"
                    placeholder={
                      visibility === "private"
                        ? "Enter your email"
                        : "Enter Password"
                    }
                    required
                  />
                </div>

                {/* {isPasswordFalse && (
                  <div class="alert alert-danger" role="alert">
                    Incorrect Password!
                  </div>
                )} */}
                {isPasswordFalse && (
                  <p className="collection-auth-error-msg">
                    {visibility === "private"
                      ? "Invalid email"
                      : "Incorrect Password"}
                    {/* Incorrect Password  */}
                  </p>
                )}
                <div className="pass-sub-btn">
                  <input
                    className="btn btn-primary"
                    type="submit"
                    value="Submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordDialog;
