import React, { useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { sendProductEnquiryEmail } from "../../store/api";
import ModalWrapper from "./ModalWrapper";
import "react-toastify/dist/ReactToastify.css";
export const ProductEnquiry = ({
  apiPayload,
  title,
  countryCodes,
  setChange,
}) => {
  let fields = [
    {
      id: "request_made_by_name",
      name: "Name *",
      tag: "input",
      required: true,
      pattern: /^[a-zA-Z ]*$/,
      type: "text",
      placeholder: "Enter your name",
    },
    {
      id: "request_made_by_email_address",
      name: "Email *",
      tag: "input",
      required: true,
      pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
      type: "email",
      placeholder: "Enter your email address",
    },
    {
      id: "country",
      name: "Country code",
      tag: "select",
      required: false,
      pattern: null,
      type: "text",
      placeholder: "Select code",
      data: countryCodes,
    },
    {
      id: "phone_number",
      name: "Phone",
      tag: "input",
      required: false,
      pattern: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
      type: "tel",
      placeholder: "Enter your phone no",
    },
    {
      id: "company",
      name: "Company",
      tag: "input",
      required: false,
      pattern: /^[a-zA-Z ]*$/,
      type: "text",
      placeholder: "Tell us about yourself",
    },
    {
      id: "message",
      name: "Details *",
      tag: "textarea",
      required: true,
      pattern: null,
      type: "text",
      placeholder: "Tell us what you need to know",
    },
  ];
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      request_made_by_name: "",
      request_made_by_email_address: "",
      country: "",
      phone_number: "",
      company: "",
      message: "",
    },
  });

  let Status = {
    prodEnqResponse: {
      state: false,
      severity: "",
      message: "",
    },
    loader: false,
  };
  const prodEnqReducer = (state, action) => {
    switch (action.type) {
      case "PROD_ENQ_API_RESPONSE": {
        if (action.state === false) {
          return {
            ...state,
            prodEnqResponse: {
              ...[state.prodEnqResponse],
              state: action.state,
              message: "",
              severity: "",
            },
          };
        }
        if (action.state === true) {
          return {
            ...state,
            prodEnqResponse: {
              ...[state.prodEnqResponse],
              state: action.state,
              message: action.message,
              severity: action.severity,
            },
          };
        }
      }
      case "API_LOADER": {
        return {
          ...state,
          loader: action.state,
        };
      }
      default: {
        return state;
      }
    }
  };
  async function submit(data) {
    prodEnqDispatch({ type: "API_LOADER", state: true });
    await sendProductEnquiryEmail({
      message_from: `This message is sent from (${window.location.href})`,
      ...apiPayload,
      ...data,
    })
      .then((res) => {
        // console.log("res",res);
        if (res?.Status.toLowerCase() === "success") {
          toast.success(
            "Thank you for your enquiry. We will get back to you soon."
          );
        } else {
          toast.error(res?.message);
        }
        setTimeout(() => {
          setChange(false);
        }, 3000);
        prodEnqDispatch({ type: "API_LOADER", state: false });
        const productEnqWrapper=document.getElementsByClassName("prod-enq-wrapper")[0]
        productEnqWrapper.style.opacity =
          "0.4";
          productEnqWrapper.style.pointerEvents = "none";
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  useEffect(() => {
    reset({
      request_made_by_name: "",
      request_made_by_email_address: "",
      country: "",
      phone_number: "",
      company: "",
      message: "",
    });
  }, [isSubmitSuccessful]);

  const [prodEnqState, prodEnqDispatch] = useReducer(prodEnqReducer, Status);

  return (
    <>
      <ModalWrapper setState={setChange}>
      <ToastContainer
            position="top-center"
            autoClose={3000}
            limit={2}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        <div className="prod-enq-wrapper">
          <h2 className="prod-enq-title">{title}</h2>
          <form className="prod-enq-form" onSubmit={handleSubmit(submit)}>
            <div className="prod-enq-form-scroll">
              {fields.map((onefield, index) => {
                return (
                  <>
                    {onefield?.tag === "input" ? (
                      <div className="prod-enq-field">
                        <label id="prod-enq-label" htmlFor={onefield?.id}>
                          {onefield?.name}
                        </label>
                        <input
                          placeholder={onefield?.placeholder}
                          {...register(onefield?.id, {
                            required: onefield?.required,
                            pattern: onefield?.pattern,
                          })}
                          type={onefield?.type}
                          id={onefield?.id}
                          className={
                            errors?.[onefield?.id]
                              ? "prod-enq-input-err"
                              : "prod-enq-input"
                          }
                        ></input>
                        {errors?.[onefield?.id] &&
                          (errors?.[onefield?.id].type === "required" ? (
                            <span
                              style={{
                                color: "rgb(139, 139, 85)",
                                fontSize: "0.6rem",
                              }}
                            >
                              This field is required !!
                            </span>
                          ) : errors?.[onefield?.id].type === "pattern" ? (
                            <span
                              style={{
                                color: "rgb(139, 139, 85)",
                                fontSize: "0.6rem",
                              }}
                            >
                              Invalid input !!
                            </span>
                          ) : null)}
                      </div>
                    ) : null}

                    {onefield?.tag === "select" ? (
                      <div key={index} className="prod-enq-field">
                        <label id="prod-enq-label" htmlFor={onefield?.id}>
                          {onefield?.name}
                        </label>
                        <select
                          {...register(onefield?.id, {
                            required: onefield?.required,
                            pattern: onefield?.pattern,
                          })}
                          type={onefield?.type}
                          className="prod-enq-input"
                          id={onefield?.id}
                        >
                          <option
                            style={{ fontSize: "0.6rem", fontWeight: "400" }}
                            value=""
                            selected
                          >
                            Select country code
                          </option>
                          {typeof onefield?.data !== "undefined"
                            ? onefield?.data?.map((option, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={option?.phone_country_code}
                                  >
                                    {option?.phone_country_code}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    ) : null}
                    {onefield?.tag === "textarea" ? (
                      <div key={index} className="prod-enq-field">
                        <label id="prod-enq-label" htmlFor={onefield?.id}>
                          {onefield?.name}
                        </label>
                        <textarea
                          style={{ resize: "vertical", maxHeight: "2.5rem" }}
                          placeholder={onefield?.placeholder}
                          {...register(onefield?.id, {
                            required: onefield?.required,
                            pattern: onefield?.pattern,
                          })}
                          type={onefield?.type}
                          className={
                            errors?.[onefield?.id]
                              ? "prod-enq-input-err"
                              : "prod-enq-input"
                          }
                          id={onefield?.id}
                        ></textarea>
                        {errors?.[onefield?.id] &&
                          (errors?.[onefield?.id].type === "required" ? (
                            <span
                              style={{
                                color: "rgb(139, 139, 85)",
                                fontSize: "0.6rem",
                              }}
                            >
                              This field is required !!
                            </span>
                          ) : null)}
                      </div>
                    ) : null}
                  </>
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "10px",
              }}
            >
              <button
                className="custom-button custom-button--primary custom-button--px-1-py-05 custom-button--color-white custom-button--border-none custom-button__box"
                type="submit"
                style={{
                  width: "100%",
                }}
                variant="filled"
              >
                <p style={{ margin: 0, marginRight: 6 }}>SUBMIT</p>
                {prodEnqState?.loader === true ? (
                  <div class="spinner-border text-light " role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : null}
              </button>
            </div>
          </form>
         
        </div>
      </ModalWrapper>
    </>
  );
};
