import React from "react";

export const Template4PageContent = ({title,description}) => {
  return (
    <>
    <section className=" pages-wrapper">
        <div className=" content-pages-inner-wrapper">
           <h2 className="asset-typo--title">{title}</h2>
           <div className="asset-typo--description" dangerouslySetInnerHTML={{__html:description}}></div>
        </div>
   
    </section>
    </>
  );
};
