import React, { useState } from "react";
import Template2ProductTabs from "./Template2ProductTabs";
import Template2ProductTabContent from "./Template2ProductTabContent";
import { Template2Footer } from "../Template2Footer";
import { Template2AssetFooter } from "../Template2AssetFooter";

const Template2ProductAssets = ({ data ,settings}) => {
  const [selectTabs, setSelectTabs] = useState("Description");
  const tabsChange = (e) => {
    setSelectTabs(e);
  };
  return (
    <div>
      <Template2ProductTabs
        data={data}
        selectedTab={selectTabs}
        tabsChange={tabsChange}
      />
      <Template2ProductTabContent data={data} selectedTab={selectTabs} />
      <Template2AssetFooter settings={settings}/>
    </div>
  );
};

export default Template2ProductAssets;
