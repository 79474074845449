import React, { useEffect, useState } from "react";
import { Template2Footer } from "../Template2Footer";

import { FaDownload } from "react-icons/fa";
import { DownloadThreeD } from "../../../../../utils/functions";

import { useLeadFeature } from "../../../../../utils/hooks";
//Redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateState } from "../../../../../store/slice/state";

export const Template2ThreeDContent = ({
  title,
  description,
  sourceURL,
  allowDownload,
  isCaptureLeadBeforeDownloadingTheAsset,
  data,
}) => {
  const { isLeadSent, isLeadCapturedForDownloadingAsset } = useSelector((state) => state.state);
  const globalDispatch = useDispatch();

  const [features, setFeatures] = useState({
    isLeadActive: {
      from:
        isLeadSent === false && isCaptureLeadBeforeDownloadingTheAsset === "Yes"
          ? "PAGE"
          : "AFTER-SHARE",
      value:
        isLeadSent === false && isCaptureLeadBeforeDownloadingTheAsset === "Yes"
          ? true
          : false,
    },
  });
  const [downloadAsset, setDownloadAsset] = useState(null);
  const { LeadFeatureDesign } = useLeadFeature({
    exhibitorID: data,
    changeState: setFeatures,
    isActive: downloadAsset,
  });

  const [isDownloadBtnClicked, setIsDownloadBtnClicked] = useState(null);
  let downloadCheckInterval;
  const downloadAssetFn = () => {
    setIsDownloadBtnClicked(true);
    if (isLeadSent === false) {
      setDownloadAsset(features?.isLeadActive?.value);
      downloadCheckInterval = setInterval(checkAssetDownloadFn, 1000);

      if (isCaptureLeadBeforeDownloadingTheAsset === "No") {
        DownloadThreeD(sourceURL);
      }
    }
    if (isLeadSent === true) {
      DownloadThreeD(sourceURL);
    }
  };
  function checkAssetDownloadFn() {
    if (isLeadCapturedForDownloadingAsset === true && isDownloadBtnClicked === true) {
      DownloadThreeD(sourceURL);
      globalDispatch(updateState({ isLeadCapturedForDownloadingAsset: false }));
      clearInterval(downloadCheckInterval);
      setIsDownloadBtnClicked(false);
    }
    clearInterval(downloadCheckInterval);
  }
  useEffect(() => {
    downloadCheckInterval = setInterval(checkAssetDownloadFn, 1000);
  }, [isLeadCapturedForDownloadingAsset]);

  useEffect(() => {
    if (isLeadSent === true) {
  //    DownloadThreeD(sourceURL);
      setDownloadAsset(features?.isLeadActive?.value);
    }
  }, [features?.isLeadActive?.value]);
  return (
    <>
      <section
        className="content--wrapper"
        style={{
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "2rem",
          padding: "5rem 0px 0px 0px",
        }}
      >
        <div
          className="content--inner-wrapper"
          style={{ width: "100%", maxWidth: "100%", padding: "1rem" }}
        >
          <h2 className="asset-typo--title">
            {title}{" "}
            {allowDownload == "Yes" ? (
              <FaDownload
                className="custom-button__round--primary custom-button--w-20 custom-button"
                onClick={() => {
                  downloadAssetFn();
               
                }}
              />
            ) : null}
          </h2>
          <div
            className="asset-typo--description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
          <div className="custom-model-viewer">
            <model-viewer
              style={{ width: "100%", height: "100%", borderRadius: "1rem" }}
              alt="Source 3D Model"
              src={sourceURL}
              ar
              ar-modes="scene-viewer quick-look webxr"
              environment-image=""
              poster=""
              shadow-intensity="1"
              camera-controls
              touch-action="pan-y"
              xr-environment
            ></model-viewer>
          </div>
        </div>
      </section>
      <LeadFeatureDesign />
    </>
  );
};
