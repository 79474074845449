import React, { useState } from "react";
//react-icons
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
//Lazy loader
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// components
import { chooseGuideBookContent } from "../../../../../utils/chooseGuideBookContent";
import { Template6Footer } from "../Template6Footer";

import { GrNext } from "react-icons/gr";

const Template6CollectionContainer = ({ data, selectedTab }) => {
  const { collectionURL } = useParams();
  // const navigate = useNavigate();

  // function goToIndividualAsset(contentType, ID) {
  //   navigate(`/${collectionURL}/${contentType}/${ID}`);
  // }
  //console.log(Object.values(data), "datatatat");

  return (
    <>
      <div className="temp6-coltn-container-overall">

        <div id="carouselExample" className="carousel slide">
          <div className="carousel-inner">
            {data[Object.keys(data)[0]]?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    index === 0 ? "carousel-item active" : "carousel-item"
                  }
                >
                  <div className="presentation-content-preview">
                    {chooseGuideBookContent(
                      item?.identifiedAs.toLowerCase(),
                      item?.source,
                      item?.description
                    )}
                    <div className="temp6-bootstrap-carousel-btns">
              <button
                className="carousel-control-prev temp6-carousel-prev-btn"
                type="button"
                data-bs-target="#carouselExample"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
              </button>
              <button
                className="carousel-control-next temp6-carousel-next-btn"
                type="button"
                data-bs-target="#carouselExample"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
              </button>
            </div>
                  </div>
                  <h2 className="temp6-presentation-title">{item?.title}</h2>
                  {item?.identifiedAs.toLowerCase() !== 'pages' ? (<div
                    className="temp6-presentation-desc"
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  ></div>) : null}




                </div>
              );
            })}


         
          </div>



        </div>
      </div>
      <Template6Footer />
    </>
  );
};

export default Template6CollectionContainer;

// {data[selectedTab]?.map((item, index) => {
//   return (
//     <div
//       key={index}
//       className="temp1-product-tab-content-images-item carousel-item"
//       onClick={() =>
//         goToIndividualAsset(
//           item?.identifiedAs.toLowerCase(),
//           item?._id
//         )
//       }
//     >
//       <LazyLoadImage
//         alt=""
//         effect="blur"
//         src={item.thumbnailImageURL}
//       />
//       {/* <img src={item.thumbnailImageURL} /> */}
//       <div className="item-body">
//         <p className="item-name">
//           {item.title.length > 70
//             ? `${item.title.substring(0, 70)}...`
//             : item.title}
//         </p>
//       </div>
//     </div>
//   );
// })}

{
  /* <div id="carouselExample" className="carousel slide"> */
}
{
  /* <div className="carousel-indicators">
  <button
    type="button"
    data-bs-target="#carouselExampleIndicators"
    data-bs-slide-to="0"
    class="active"
    aria-current="true"
    aria-label="Slide 1"
  ></button>
  <button
    type="button"
    data-bs-target="#carouselExampleIndicators"
    data-bs-slide-to="1"
    aria-label="Slide 2"
  ></button>
  <button
    type="button"
    data-bs-target="#carouselExampleIndicators"
    data-bs-slide-to="2"
    aria-label="Slide 3"
  ></button>
</div> */
}
{
  /* <div className="carousel-inner"> */
}
{
  /* {data[selectedTab]?.map((item, index) => {
    return (
      <div style={{fontSize:"35px",textAlign:"center",background:"#303030"}}
        key={index}
        className={
          index === 0 ? "carousel-item active w-100" : "carousel-item w-100"
        }
        onClick={() =>
          goToIndividualAsset(
            item?.identifiedAs.toLowerCase(),
            item?._id
          )
        }
      >
        <div dangerouslySetInnerHTML={{ __html: item?.description }}></div>
       
      </div>
    );
  })}  */
}

{
  /* <div class="carousel-item active">
<svg class="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="800" height="400" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: First slide" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#777"></rect><text x="50%" y="50%" fill="#555" dy=".3em">First slide</text></svg>
</div>
<div class="carousel-item">
<svg class="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="800" height="400" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Second slide" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#666"></rect><text x="50%" y="50%" fill="#444" dy=".3em">Second slide</text></svg>
</div>
<div class="carousel-item">
<svg class="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="800" height="400" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Third slide" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#555"></rect><text x="50%" y="50%" fill="#333" dy=".3em">Third slide</text></svg>
</div>
</div>
<button
  className="carousel-control-prev"
  type="button"
  data-bs-target="#carouselExampleIndicators"
  data-bs-slide="prev"
>
  <span
    className="carousel-control-prev-icon"
    aria-hidden="true"
  ></span>
  <span className="visually-hidden">Previous</span>
</button>
<button
  className="carousel-control-next"
  type="button"
  data-bs-target="#carouselExampleIndicators"
  data-bs-slide="next"
>
  <span
    className="carousel-control-next-icon"
    aria-hidden="true"
  ></span>
  <span className="visually-hidden">Next</span>
</button>
</div> */
}


{/* <div className="carousel-item active">
          <svg className="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="800" height="400" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: First slide" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#777"></rect><text x="50%" y="50%" fill="#555" dy=".3em">First slide</text></svg>
        </div>
        <div className="carousel-item">
          <svg className="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="800" height="400" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Second slide" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#666"></rect><text x="50%" y="50%" fill="#444" dy=".3em">Second slide</text></svg>
        </div>
        <div className="carousel-item">
          <svg className="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="800" height="400" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Third slide" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#555"></rect><text x="50%" y="50%" fill="#333" dy=".3em">Third slide</text></svg>
        </div> */}