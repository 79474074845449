import React, { useState } from "react";
import { Template3MobileSections } from "./Template3MobileSections";
import { Template3MobileAssets } from "./Template3MobileAssets";

export const Template3MobileGuide = ({ data, settings, setFeatures }) => {
  const [viewName, setViewName] = useState("sections");
  const [displayAsset, setDisplayAsset] = useState(null);
  return (
    <>
      {viewName === "sections" && (
        <Template3MobileSections
          data={data}
          settings={settings}
          setFeatures={setFeatures}
          setViewName={setViewName}
          setDisplayAsset={setDisplayAsset}
         // titleDesc={titleDesc}
        />
      )}
      {viewName === "assets" && (
        <Template3MobileAssets
          setViewName={setViewName}
          displayAsset={displayAsset}
        />
      )}
    </>
  );
};
