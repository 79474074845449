import React from "react";
import { Template4Header } from "../Template4Header";
import { Template4Footer } from "../Template4Footer";
import { Template4DocumentContent } from "./Template4DocumentContent";

import { Template4AssetFooter } from "../Template4AssetFooter";

export const Template4Document = ({ data , setFeatures , settings , goBack}) => {
 // console.log(data);
  return ( 
    <>
      <div className="page">
        <Template4Header logoURL={data?.logo} setFeatures={setFeatures} settings={settings} goBack={goBack}/>
        <Template4DocumentContent
          title={data?.asset_details.title}
          description={data?.asset_details.description}
          sourceURL={data?.asset_details.source_url}
          allowDownload={data?.settings?.allowDownload}  isCaptureLeadBeforeDownloadingTheAsset={
            data?.settings?.isCaptureLeadBeforeDownloadingTheAsset
          }
          data={data?.exhibitor_id}
        />
    <Template4AssetFooter settings={settings}/>
      </div>
    </>
  );
};
