import React from "react";

import { useState } from "react";
import { Template3DesktopLeftSection } from "./Template3DesktopLeftSection";
import { Template3DesktopRightSection } from "./Template3DesktopRightSection";

export const Template3DesktopGuide = ({ data, settings, setFeatures }) => {
  const [displayAsset, setDisplayAsset] = useState(null);

  // console.log(data?.data?.sectionWiseAssets,"data");
  return (
    <>
      <div className="temp3-desk-overall">
        {/* <div className="temp3-veespaces-logo">
          <div>
            <a href="https://www.veespaces.com/" target="_blank">
          <img src='https://www.veespaces.com/wp-content/themes/veespaces/images/veespaces2023.png'/></a></div>
        </div> */}
        <Template3DesktopLeftSection
          titleDesc={data?.branding}
          logo={data?.branding?.collectionLogo}
          sectionAssets={data?.data?.sectionWiseAssets}
          setDisplayAsset={setDisplayAsset}
        />
        <Template3DesktopRightSection
          settings={settings}
          displayAsset={displayAsset}
          setFeatures={setFeatures}
        />

        {settings?.isPoweredByLogoEnabled === 'Yes' ? (
          <div className="temp3-footer-logo-sec"><div className="temp1-footer-img">
            <a
              href={
                "https://www.veespaces.com/?utm_source=client-website&utm_medium=created-by-logo-collection&utm_campaign=created-by-veespaces-logo"
              }
              target="_blank"
            >
              <img
                width="auto"
                height="auto"
                style={{ maxWidth: "100px", maxHeight: "50px" }}
                src={'https://space.veespaces.com/PoweredLogo.png'}
                alt="logo"
              />
            </a>
          </div></div>) : (null)}



      </div>
    </>
  );
};
