import React from "react";
import { VideoPlayer } from "../../../../shared/VideoPlayer";

export const Template4VideoContent = ({title,description,sourceURL}) => {
  return (
    <>
    <section className="content--wrapper temp1-link1-content-wrapper">
        <div className="content--inner-wrapper temp1-link1-inner-wrapper">
           <h2 className="asset-typo--title">{title}</h2>
           <div className="asset-typo--description" dangerouslySetInnerHTML={{__html:description}}></div>
           <div className="custom-video-player">
           <VideoPlayer  videoURL={sourceURL}/>
           </div>
          
        </div>
    </section>
    </>
  );
};
