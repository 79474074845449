import React from 'react';
import { chooseGuideBookContent } from '../../../../../utils/chooseGuideBookContent';
import { FaDownload } from 'react-icons/fa';
import { BiArrowBack } from 'react-icons/bi';
import { DownloadDocument, DownloadImage } from '../../../../../utils/functions';

export const Template3MobileAssets = ( { displayAsset, setViewName } ) =>
{
  return (
    <>
      <div className='temp3-mobile-overall'>
        <div className='temp3-mobile__header'>
          <BiArrowBack className='custom-button--color-white custom-button--w-15' onClick={ () => { setViewName( 'sections' ); } } style={ { cursor: 'pointer' } } />
        </div>
        <div className="temp3-right-sec__content">
          <div className="guide-book-title-box">
            <h2 className="guide-book-title-box__title">
              { displayAsset?.title }
            </h2>
            { displayAsset?.identifiedAs.toLowerCase() === 'images' || displayAsset?.identifiedAs.toLowerCase() === 'documents' ? (
              <>
                { displayAsset?.allowDownload === 'Yes' ?
                  <FaDownload onClick={ () =>
                  {
                    if ( displayAsset?.identifiedAs.toLowerCase() === 'images' )
                    {
                      DownloadImage( displayAsset?.source );
                    }
                    if ( displayAsset?.identifiedAs.toLowerCase() === 'documents' )
                    {
                      DownloadDocument( displayAsset?.source );
                    }
                  } } className="custom-button__round--primary custom-button--w-20 custom-button" /> : null }</> ) : null }
          </div>
          <div
            className="guide-book__desc"
            dangerouslySetInnerHTML={ { __html: displayAsset?.description } }
          ></div>
          <div className="guide-book-content-preview">
            { chooseGuideBookContent(
              displayAsset?.identifiedAs.toLowerCase(),
              displayAsset?.source
            ) }
          </div>
        </div>

      </div>
    </>
  );
};
