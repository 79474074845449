import React from "react";
import { Template2Header } from "../Template2Header";
import { Template2Footer } from "../Template2Footer";

import { Template2VideoContent } from "./Template2VideoContent";
import { Template2AssetFooter } from "../Template2AssetFooter";

export const Template2Video = ({ data, setFeatures, settings, goBack }) => {
  return (
    <>
      <div className="page">
        <Template2Header logoURL={data?.logo} setFeatures={setFeatures} settings={settings} goBack={goBack} />
        <Template2VideoContent title={data?.asset_details.title} description={data?.asset_details.description} sourceURL={data?.asset_details.source_url} />

        <Template2AssetFooter settings={settings}/>
      </div>
    </>
  );
};


