import React from "react";
import { Template1Header } from "../Template1Header";
import { Template1Footer } from "../Template1Footer";
import { Template1LinkContent } from "./Template1LinkContent";
import { Template1AssetHeader } from "../Template1AssetHeader";
import { Template1AssetFooter } from "../Template1AssetFooter";

export const Template1Link = ({ data, setFeatures, settings, goBack }) => {
  return (
    <>
      <div className="page">
        <Template1AssetHeader data={data} logoURL={data?.logo} setFeatures={setFeatures} settings={settings} goBack={goBack} />
        <Template1LinkContent title={data?.asset_details.title} description={data?.asset_details.description} sourceURL={data?.asset_details.source_url} />
        <Template1AssetFooter settings={settings} />
      </div>
    </>
  );
};
