import React from "react";
import { useEffect } from "react";

export const Template5VideoAlbumBanner = (data) => {
  const collectionTitle = data?.data?.collectionHeading;
  let collectionDesc = data?.data?.collectionDescription;
  let bannerBgImgURL = data?.data?.collectionBanner?.source;

  return (
    <div>
      <div
        className="banner-title-desc video-banner"
        style={{
          backgroundImage: { bannerBgImgURL } ? `url(${bannerBgImgURL})` : null,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        
        }}
      >
        <h2>{collectionTitle}</h2> 
        <span></span>
        <div
          id="collection-desc"
          dangerouslySetInnerHTML={{ __html: collectionDesc }}
        ></div>
      </div>
    </div>
  );
};


