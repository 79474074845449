import React, { useRef, useEffect, useState } from "react";
import { DocumentViewer } from "react-documents";
import { ColorRing } from "react-loader-spinner";
export const DocumentView = ({ URL }) => {
  const [loaded, setLoaded] = useState(true);
  const iframeRef = useRef(null);
  const interval = useRef();
  const encodedURL = encodeURIComponent(URL);
  console.log(encodedURL)
  const clearCheckingInterval = () => {
    clearInterval(interval.current);
  };

  const onIframeLoaded = () => {
    clearCheckingInterval();
    setLoaded(false);
  };
  useEffect(() => {
    interval.current = setInterval(() => {
      try {
        if (iframeRef.current.contentWindow.document.body.innerHTML === "") {
         

          iframeRef.current.src =
            `https://docs.google.com/gview?url=${encodedURL}&embedded=true`;
        }
      } catch (e) {
        onIframeLoaded();
      }
    }, 3000);

    return clearCheckingInterval;
  }, []);
  return (
    <>
      {loaded && (
        <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
          <h2>Loading....</h2>
          <h2>Please wait</h2>
          <ColorRing
            visible={loaded}
            height="30%"
            width="30%"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </div>
      )}
      <div style={{ width: "100%", height: "100%" }}>
        <iframe
          style={{ width: "100%", height: "100%" }}
          onLoad={onIframeLoaded}
          ref={iframeRef}
          src={`https://docs.google.com/gview?url=${encodedURL}&embedded=true`} 
          title="PDF Viewer"
        />
      </div>
    </>
  );
};
{
  /* <DocumentViewer
        queryParams="hl=Nl"
        googleCheckContentLoaded="true"
        googleCheckInterval = "1000 "
        googleMaxChecks = "30"
        url={URL}
        className=""
        viewerUrl={"https://docs.google.com/gview?url=%URL%&embedded=true"}
        viewer={"url"}
        overrideLocalhost="https://react-doc-viewer.firebaseapp.com/"
        loaded={async (res) => {
            console.log(res,"doc");
          // documentPageDispatch({
          //   type: 'UPDATE_DOC_LOADER',
          //   value: false,
          // });
        }}
      ></DocumentViewer> */
}
