import React from "react";
import { BsFillChatLeftTextFill, BsShareFill } from "react-icons/bs";
import { FaInfoCircle } from "react-icons/fa";
import { IoCloseCircleOutline } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { Template3MobilePopup } from "./Template3MobilePopup";
import { useState } from "react";

//Redux
import { useDispatch } from "react-redux";
import { updateState } from "../../../../../store/slice/state";


export const Template3MobileSections = ({
  data,
  settings,
  setFeatures,
  setViewName,
  setDisplayAsset,
}) => {
  console.log(settings, "set");
  const [viewSectionList, setViewSectionList] = useState({
    status: false,
    assetList: [],
    sectionTitle: null,
    sectionThumbnail: null,
  });
  const globalDispatch = useDispatch();
  const descriptionInfoBtn = document.getElementById("temp3-left-sec-info-btn");

  const showHideDescription = () => {
    document
      .getElementsByClassName("temp3-left-sec-desc")[0]
      .classList.toggle("temp3-left-sec-desc-show");
  };

  return (
    <>
      <div className="temp3-mobile-overall">
        <div className="temp3-mobile__header">
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
            <img
              className="temp3-mobile__header__logo"
              src={data?.branding?.collectionLogo}
            />
            <div className="temp3-left-sec-title">
              <h6>{data?.branding?.collectionHeading}</h6>

              {data?.branding?.collectionDescription != "" ||
                data?.branding?.collectionDescription != null ? (
                <div
                  id="temp3-left-sec-info-btn"
                  onClick={() => showHideDescription()}
                >
                  <FaInfoCircle />
                </div>
              ) : null}
              <div className="temp3-left-sec-desc temp3-left-sec-mobile">
                <IoCloseCircleOutline onClick={() => showHideDescription()} />
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.branding?.collectionDescription,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="header__features-box">
            {settings?.isShareFormEnabled === "Yes" ? (
              <button
                id="email_share_button"
                className="custom-button custom-button__round custom-button--border-white-2 custom-button--w-40 custom-button--transparent"
                onClick={() => {
                  setFeatures((prevState) => ({
                    ...prevState,
                    isShareActive: true,
                  }));
                }}
              >
                <MdEmail className="custom-button--color-white custom-button--w-15" />
              </button>
            ) : null}
            {settings?.isContactUsFormEnabled === "Yes" ? (
              <button
                id="contact_us_button"
                className="custom-button custom-button__round custom-button--border-white-2 custom-button--w-40 custom-button--transparent"
                onClick={() => {
                  setFeatures((prevState) => ({
                    ...prevState,
                    isContactUsActive: true,
                  }));
                }}
              >
                <BsFillChatLeftTextFill className="custom-button--color-white custom-button--w-15" />
              </button>
            ) : null}
            <button
              id="social_share_button"
              className="custom-button custom-button__round custom-button--border-white-2 custom-button--w-40 custom-button--transparent"
              onClick={async () => {
                if (navigator.share) {
                  navigator.share({
                    title: `${document.title}`,
                    url: window.location.href,
                    text: `${document.title}`,
                  });
                } else {
                  globalDispatch(updateState({ isSocialShareOpen: true }));
                }
              }}
            >
              <BsShareFill className="custom-button--color-white custom-button--w-15" />
            </button>
          </div>
        </div>
        <div className="temp3-mobile__sections-list">
          {data?.data?.sections.map((section) => {
            return (
              <>
                <div
                  className="temp3-mobile__section"
                  onClick={() => {
                    setViewSectionList({
                      status: true,
                      assetList:
                        data?.data?.sectionWiseAssets[section?.sectionTitle],
                      sectionTitle: section?.sectionTitle,
                      sectionThumbnail: section?.sectionThumbnail,
                    });
                  }}
                >
                  {section?.sectionThumbnail !== "undefined" &&
                    section?.sectionThumbnail !== null &&
                    section?.sectionThumbnail !== "" ? (
                    <img
                      alt="section thumbnail"
                      className="temp3-mobile__section__logo"
                      src={section?.sectionThumbnail}
                    />
                  ) : null}
                  <p className="temp3-mobile__section__title">
                    {section?.sectionTitle}
                  </p>
                </div>
              </>
            );
          })}
        </div>
        {viewSectionList?.status === true && (
          <Template3MobilePopup
            viewSectionList={viewSectionList}
            setViewSectionList={setViewSectionList}
            setViewName={setViewName}
            setDisplayAsset={setDisplayAsset}
          />
        )}
      
      {settings?.isPoweredByLogoEnabled === 'Yes' ? (
        <div className="temp1-footer-logo-sec"><div className="temp1-footer-img">
          <a
            href={
              "https://www.veespaces.com/?utm_source=client-website&utm_medium=created-by-logo-collection&utm_campaign=created-by-veespaces-logo"
            }
            target="_blank"
          >
            <img
              width="auto"
              height="auto"
              style={{ maxWidth: "100px", maxHeight: "50px" }}
              src={'https://space.veespaces.com/PoweredLogo.png'}
              alt="logo"
            />
          </a>
        </div></div>) : (null)}

    
      </div>
    </>
  );
};
