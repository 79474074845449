import React from "react";
import { MdKeyboardArrowUp } from "react-icons/md";
import { BsArrowRight } from "react-icons/bs";
import { memo } from "react";
import { useEffect, useState } from "react";
import { useCallback } from "react";

//Icons
import { BiSad } from "react-icons/bi";

export const Template3SectionList = memo(
  ({ sectionAssets, setDisplayAsset }) => {
   
  //   console.log(sectionAssets,"data");
    // const [sectionChange,setSectionChange]=useState('')
    const openSection = (sectionName, firstAsset, firstAssetClassName, e) => {
      

      let sectionScrolledTo = document.getElementsByClassName(
        `section-${sectionName.split(" ").join("")}`
      );

      let icon = document.querySelectorAll(".temp3-section__drop-icon");
      let assets = document.querySelectorAll(".temp3-section__assets-box");
      icon.forEach((iconSection) => {
        if (iconSection.getAttribute("dataSection") === sectionName) {
          if (iconSection.classList.contains("rotate")) {
            iconSection.classList.remove("rotate");
            //  console.log("1");
          } else {
            iconSection.classList.add("rotate");
            //   console.log("2");
          }
        } else {
          if (iconSection.classList.contains("rotate")) {
            iconSection.classList.remove("rotate");
            //  console.log("3");
          }
          //  console.log("4");
        }

        assets.forEach((asset) => {
          if (asset.getAttribute("dataSection") === sectionName) {
            if (asset.classList.contains("active-asset")) {
              asset.classList.remove("active-asset");
            }
            if (!asset.classList.contains("active-asset")) {
              asset.classList.add("active-asset");
              makeAssetActive(firstAsset, firstAssetClassName);
              // sectionScrolledTo.scrollIntoView({
              //   block: "start",
              //   inline: "nearest",
              //   behavior: "smooth",
              // });
            }
          } else {
            if (asset.classList.contains("active-asset")) {
              asset.classList.remove("active-asset");
            }
          }
        });
      });
    };

    const makeAssetActive = (asset, className) => {
      // console.log(asset,className);
      let allElements = document.querySelectorAll(".temp3-section__asset");
      allElements.forEach((element) => {
        if (
          element.childNodes[0].classList.contains(
            "custom-button--color-primary"
          ) &&
          element.childNodes[0].classList.contains("font-weight-6")
        ) {
          element.childNodes[0].classList.remove(
            "custom-button--color-primary",
            "font-weight-6"
          );
        }

        if (
          element.childNodes[1].classList.contains(
            "custom-button--color-primary"
          ) &&
          element.childNodes[0].classList.contains("font-weight-6")
        ) {
          element.childNodes[1].classList.remove(
            "custom-button--color-primary",
            "font-weight-6"
          );
        }
      });
      let element = document.querySelector(`.${className}`);
      if (
        !element.childNodes[0].classList.contains(
          "custom-button--color-primary"
        ) &&
        !element.childNodes[0].classList.contains("font-weight-6")
      ) {
        element.childNodes[0].classList.add(
          "custom-button--color-primary",
          "font-weight-6"
        );
      }

      if (
        !element.childNodes[1].classList.contains(
          "custom-button--color-primary"
        ) &&
        !element.childNodes[0].classList.contains("font-weight-6")
      ) {
        element.childNodes[1].classList.add(
          "custom-button--color-primary",
          "font-weight-6"
        );
      }

      setDisplayAsset(asset);
    };

    const setDefaultOpenSection = useCallback(() => {
      let sectionName = Object.keys(sectionAssets)[0];
      let displayAssetData = sectionAssets[Object.keys(sectionAssets)[0]][0];
      let displayAssetClassName = `asset-${
        sectionAssets[Object.keys(sectionAssets)[0]]?.[0]?._id
      }`;

     // console.log(displayAssetData && displayAssetClassName)
      if(displayAssetData && displayAssetClassName){
        openSection(sectionName, displayAssetData, displayAssetClassName);
      }
     
    }, [sectionAssets]);

    useEffect(() => {
     // console.log(sectionAssets)
      setDefaultOpenSection();
    }, []);
    //temp3-left-sec__list - overall
    //section-${section} - inside
    return (
      <>
        <div className="temp3-left-sec__list">
          {Object.keys(sectionAssets).map((section, index) => {
            return (
              <>
                <div
                  key={`${section}_${index}`}
                  className={`temp3-section section-${section
                    .split(" ")
                    .join("")}`}
                >
                  <div className="temp3-section__title-box">
                    <div className="temp3-section__title-box__thumbnail-box"></div>
                    <div
                      className="temp3-section__title-cont"
                      onClick={(e) =>
                        openSection(
                          section,
                          sectionAssets[section][0],
                          `asset-${sectionAssets[section]?.[0]?._id}`,
                          e
                        )
                      }
                    >
                      <h2 className="temp3-section__title">{section}</h2>
                      <MdKeyboardArrowUp
                        className="custom-button--color-dark custom-button--w-20 temp3-section__drop-icon"
                        dataSection={section}
                      />
                    </div>
                  </div>
                  <div
                    className="temp3-section__assets-box"
                    dataSection={section}
                  >
                    {sectionAssets[section].length > 0 ? sectionAssets[section].map((asset) => {
                      return (
                        <>
                          <div
                            key={asset?._id}
                            className={`temp3-section__asset asset-${asset?._id}`}
                            onClick={() =>
                              makeAssetActive(asset, `asset-${asset?._id}`)
                            }
                          >
                            <p className="temp3-section__asset-typo--title">
                              {asset?.title}
                            </p>
                            <BsArrowRight className="custom-button--color-dark custom-button--w-12" />
                          </div>
                        </>
                      );
                    }):(
                      <>
                      <p style={{fontSize:'0.9rem',fontWeight:'400',color:'#333',padding:'0.8rem 1rem',margin:0}}>No content has been added</p>
                      <BiSad className="custom-button--w-20 custom-button--color-primary custom-button--transparent custom-button--border-none "/>
                      </>
                    )}
                  </div>
                </div>
              </>
            );
          })}
        </div>
    
      </>
    );
  }
);
