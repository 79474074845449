import React, { useState, useCallback, useEffect } from "react";

//ICONS
import { BiDownload } from "react-icons/bi";
import { Tb360View } from "react-icons/tb";

// Image Viewer
import ImageViewer from "react-simple-image-viewer";
// Redux
import { useDispatch } from "react-redux";
import { updateState } from "../../../../../store/slice/state";

const Template1ProductTabContent = ({ data, selectedTab }) => {
 // console.log(Template1VideoPlayer,"data");
  // console.log(selectedTab, "content", data?.product_videos);
  const globalDispatch = useDispatch();
  const [imgArray, setImgArray] = useState([]);
  const [imgPathArr, setImgPathArr] = useState([]);

  useEffect(() => {
    let emptyImgArray = new Array();
    let emptyImgPathArray = new Array();
    data?.product_images.map((item) => {
      emptyImgPathArray.push(item?.path);
      emptyImgArray.push({
        imagePath: item?.path,
        imageTitle: item?.title,
      });
    });
    setImgArray(emptyImgArray);
    setImgPathArr(emptyImgPathArray);
  }, []);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
 
  const openVideoPlayer = (e, videoID) => {
  //  console.log(element,"ele");
    globalDispatch(updateState({ selectedProductVideoID: videoID }));
    globalDispatch(updateState({ productVideoPlaying: true }));
    document.getElementsByClassName(
      "sections-wrapper-product-temp1"
    )[0].style.display = "none";
    document.getElementsByClassName(
      "temp1-prod-content__top-section"
    )[0].style.display = "none";
    document.getElementById("react-video-player-wrapper").style.display =
      "flex";
    document.getElementsByClassName(
      "temp1-prod-content--wrapper"
    )[0].style.display = "none";
  };

  const openModelViewer = (modelID) => {
    globalDispatch(updateState({ selectedProductModelID: modelID }));
    document.getElementsByClassName(
      "sections-wrapper-product-temp1"
    )[0].style.display = "none";
    document.getElementsByClassName(
      "temp1-prod-content__top-section"
    )[0].style.display = "none";
    document.getElementById("react-model-player-wrapper").style.display =
      "flex";
    document.getElementsByClassName(
      "temp1-prod-content--wrapper"
    )[0].style.display = "none";
  };
  //   useEffect(()=>{
  // console.log(selectedTab);
  //   },[selectedTab])

  return (
    <div className="temp1-prod-tab-content">
      {selectedTab === "Description" && (
        <div
          className="temp1-product-descrptn"
          dangerouslySetInnerHTML={{
            __html: data?.product[0]?.product_description,
          }}
        ></div>
      )}

      {selectedTab === "Images" && (
        <div className="temp1-product-tab-content-images">
          {imgArray.map((src, index) => (
            <div key={index} className="temp1-product-tab-content-images-item">
              <img
                src={src.imagePath}
                onClick={() => openImageViewer(index)}
                width="300"
                key={index}
                alt={src?.imageTitle}
              />
              <div className="item-body">
                <p className="item-name">{src?.imageTitle}</p>
              </div>
            </div>
          ))}

          {isViewerOpen && (
            <ImageViewer
              src={imgPathArr}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          )}
        </div>
      )}

      {selectedTab === "Videos" && (
        <div className="temp1-product-tab-content-videos">
          {data?.product_videos.map((item, index) => {
            return (  
              <div
                key={index}
                className="temp1-product-tab-content-videos-item temp1-product-tab-content-images-item"
                onClick={(e) => openVideoPlayer(e, item?.id)}
              >
                <img src={item?.video_thumbnail} />
                <div className="item-body">
                <p className="item-name">{item?.title}</p>
              </div>
              </div>
            );
          })}
        </div>
      )}

      {selectedTab === "Documents" && (
        <div className="temp1-product-tab-content-docs">
          {data?.product_documents.map((item, index) => {
            return (
              <div key={index} className="temp1-product-tab-content-docs-item">
                <div>
                  <p style={{ fontWeight: "600" }}>{item?.title}</p>
                  <div
                    className="temp1-product-tab-content-docs-descptn"
                    dangerouslySetInnerHTML={{
                      __html: item?.description?.substring(0, 200),
                    }}
                  ></div>
                </div>
                {item?.path != "" && item?.path != null && (
                  <a href={item?.path} target="_blank">
                    <div className="temp1-product-tab-content-docs-item-downloads">
                      <a>
                        <BiDownload /> <span>Download</span>{" "}
                      </a>
                    </div>
                  </a>
                )}
              </div>
            );
          })}
        </div>
      )}

      {selectedTab === "3D models" && (
        <div className="temp1-product-tab-content-docs">
          {data?.product_models.map((item, index) => {
            return (
              <div key={index} className="temp1-product-tab-content-docs-item">
                <div>
                  <p>{item?.["3d_object_title"]}</p>
                  <div
                    className="temp1-product-tab-content-docs-descptn"
                    dangerouslySetInnerHTML={{
                      __html: item?.["3d_object_description"]?.substring(
                        0,
                        200
                      ),
                    }}
                  ></div>
                </div>

                <div
                  className="temp1-product-tab-content-docs-item-downloads"
                  onClick={(e) => openModelViewer(item?.["3d_object_id"])}
                  style={{ cursor: "pointer" }}
                >
                  <a style={{ color: "#fff" }}>
                    <Tb360View /> <span>View</span>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Template1ProductTabContent;

// {selectedTab === "3D models" && (
//   <div className="temp1-product-tab-content-3dmodels">
//     {data?.product_models.map((item, index) => {
//       return (
//         <div
//           key={index}
//           className="temp1-product-tab-content-3dmodels-item"
//         >
//           <model-viewer
//             onClick={(e) => openModelViewer(e, item?.["3d_object_id"])}
//             style={{
//               width: "100%",
//               height: "100%",
//               borderRadius: "1rem",
//             }}
//             alt="Source 3D Model"
//             src={item?.["3d_object_path"]}
//             ar
//             ar-modes="scene-viewer quick-look webxr"
//             environment-image=""
//             poster=""
//             shadow-intensity="1"
//             camera-controls
//             touch-action="pan-y"
//             xr-environment
//           ></model-viewer>
//           {/* <img
//             src={
//               "https://appveespaces-live.s3.us-east-2.amazonaws.com/assets/glb-icon.jpeg"
//             }
//           /> */}
//         </div>
//       );
//     })}
//   </div>
// )}
