import React from "react";

export const Template2LinkContent = ({title,description,sourceURL}) => {
  return (
    <>
    <section className="content--wrapper temp1-link1-content-wrapper">
        <div className="content--inner-wrapper temp1-link1-inner-wrapper">
           <h2 className="asset-typo--title">{title}</h2>
           <div className="asset-typo--description" dangerouslySetInnerHTML={{__html:description}}></div>
           <div className="custom-video-player">
            <iframe style={{minHeight:"100%",width:"100%"}} title="Link Source" src={sourceURL} width={'100%'}></iframe>
           </div>
        </div>
    </section>
    </>
  );
};
