import React, { useEffect, useState } from "react";
import { VideoPlayer } from "../../../shared/VideoPlayer";
const Template5VideoAlbumTabContent = ({ data, selectedTab }) => {
  //console.log(data, selectedTab);
  const [videoUrl, setVideioUrl] = useState({});
  const [autoPlay, setautoPlay] = useState(true);
  const [activeVideo, setactiveVideo] = useState("");
  useEffect(() => {
    setVideioUrl({
      videoUrl: data?.[selectedTab]?.[0]?.source,
      title: data?.[selectedTab]?.[0]?.title,
      description: data?.[selectedTab]?.[0]?.description,
    });
    setactiveVideo(data?.[selectedTab]?.[0]?.title)
  }, [selectedTab]);
//   useEffect(() => {
//     const firstTab = data?.[selectedTab]?.[0];
//     console.log(firstTab);
//   //  activeVideo(firstTab);
//    // console.log(data?.[selectedTab]?.[0]?.title,"ft");
//   }, [selectedTab]);


  const selectVideoFn=(item)=>{
setVideioUrl({
    videoUrl: item?.source, 
    title:item?.title,
    description: item?.description, 
})
setactiveVideo(item?.title)
  }
  return (
    <div className="temp5-video-album-container">

      <div className="temp5-video-album-wrapper">
        <VideoPlayer videoURL={videoUrl.videoUrl}/>
        <h4>{videoUrl?.title}</h4>
        <div
          style={{ width: "100%", height: "3px", backgroundColor: "#404040" }}
        ></div>
        <div
          className="temp5-video-album-desc"
          dangerouslySetInnerHTML={{ __html: videoUrl?.description }}
        ></div>
      </div>




      <div className="temp5-video-album-list-wrapper">

        <div className="temp5-video-album-list-header">
          <h4>{selectedTab}</h4>
          <p>{data?.[selectedTab]?.length} Videos</p>
        </div>

        <div className="temp5-video-album-all-list">
            {data?.[selectedTab]?.map((item,index)=>{
                return(
                    <div key={item?._id} className={`temp5-video-album-all-videos ${activeVideo===item?.title ?"temp5-video-album-active":''}`} onClick={()=>selectVideoFn(item)}>
                         <img
                         style={{width:'200px',height:'125px',borderRadius:'6px'}} src={item?.thumbnailImageURL}/>
                        <h4>{item?.title}</h4>
                    </div>
                )
            })}
           
        </div>
      </div>


    </div>
  );
};

export default Template5VideoAlbumTabContent;
