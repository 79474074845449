import React, { useEffect, useState } from "react";
import { TemplateData } from "../../../../../store/api";
import { useAPIData } from "../../../../../utils/hooks";
import Template6CollectionBanner from "./Template6CollectionBanner";
import Template6CollectioTabs from "./Template6CollectionTabs";
import Template6CollectionContainer from "./Template6CollectionContainer";
import { Template6Header } from "../Template6Header";
import { Template6Footer } from "../Template6Footer";

export const Template6Collection = ({data,setFeatures,settings}) => {
  const [selectTabs, setSelectTabs] = useState("");
//console.log(settings,"homepage");
  useEffect(() => {
    const firstTab = data?.data?.sections[0].sectionTitle;
    setSelectTabs(firstTab);

   // document.title = `${data?.branding?.collectionTitle}`
  }, [data]);

  const tabsChange = (e) => {
    setSelectTabs(e);
  };
  return (
    <>
      {typeof data !== "undefined" ? (
        <>
          <Template6Header logoURL={data?.branding?.collectionLogo} settings={settings} setFeatures={setFeatures} goBack={{value:false,url:""}}/>
          <Template6CollectionBanner data={data?.branding} />
          {/* <Template6CollectioTabs
            data={data?.data?.sections}
            tabsChange={tabsChange}
            selectedTab={selectTabs}
          /> */}
          <Template6CollectionContainer
            data={data?.data?.sectionWiseAssets}
            selectedTab={selectTabs}
          />
       <Template6Footer settings={settings}/>
    
        </>
      ) : null}
    </>
  );
};

