import { createSlice } from '@reduxjs/toolkit';

export const state = createSlice( {
  name: 'state',
  initialState: {
    agentdetails: {
      Location_details: {
        city: 'unknown',
        city_district: 'unknown',
        country: 'unknown',
        country_code: 'unknown',
        neighbourhood: 'unknown',
        postcode: 'unknown',
        state: 'unknown',
        state_district: 'unknown',
        suburb: 'unknown',
      },
      Longitude: 'unknown',
      Latitude: 'unknown',
      Browser_CodeName: 'unknown',
      Browser_Name: 'unknown',
      Cookies_Enabled: 'unknown',
      Browser_Version: 'unknown',
      Browser_Language: 'unknown',
      Browser_Online: 'unknown',
      Platform: 'unknown',
      Device_name: 'unknown',
      Handset: 'unknown',
      OS: 'unknown',
      Browser: 'unknown',
    },
    locationCollected:false,
    isLeadSent: false,
    selectedProductVideoID:'',
    selectedProductModelID:'',
    isSocialShareOpen:false,
    productVideoPlaying:'',
    isLeadCapturedForDownloadingAsset:'',
    collectionID:''
  },
  reducers: {
    updateState: ( state, action ) =>
    {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
} );

// Action creators are generated for each case reducer function
export const { updateState } = state.actions;

export default state.reducer;
