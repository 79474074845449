import React from "react";
import { Template4Header } from "../Template4Header";
import { Template4Footer } from "../Template4Footer";

import { Template4PageContent } from "./Template4PageContent";

import { Template4AssetFooter } from "../Template4AssetFooter";
export const Template4Page = ({data,setFeatures, settings, goBack}) => {
  return (
    <>
    <div className="page"> 
      <Template4Header logoURL={data?.logo} setFeatures={setFeatures} settings={settings} goBack={goBack}/>
      <Template4PageContent title={data?.asset_details.title} description={data?.asset_details.description}/>
      
<Template4AssetFooter settings={settings}/>
    </div>
    </>
  );
};
