import React, { useEffect, useState } from "react";
import { TemplateData } from "../../../../../store/api";
import { useAPIData } from "../../../../../utils/hooks";
import Template2CollectionBanner from "./Template2CollectionBanner";
import Template2CollectioTabs from "./Template2CollectionTabs";
import Template2CollectionContainer from "./Template2CollectionContainer";
import { Template2Header } from "../Template2Header";
import { Template2Footer } from "../Template2Footer";


export const Template2Collection = ({data,settings,setFeatures}) => {
  
  const [selectTabs, setSelectTabs] = useState("");


  useEffect(() => {
    const firstTab = data?.data?.sections[0].sectionTitle;
    setSelectTabs(firstTab);
    
   // document.title = `${data?.branding?.collectionTitle}`
  }, [data]);

  const tabsChange = (e) => {
    setSelectTabs(e);
  };
  return (
    <>
      {typeof data !== "undefined" ? (
        <>
          <Template2Header logoURL={data?.branding?.collectionLogo} settings={settings} setFeatures={setFeatures} goBack={{value:false,url:""}}/>
          <Template2CollectionBanner data={data?.branding} />
          <Template2CollectioTabs
            data={data?.data?.sections}
            tabsChange={tabsChange}
            selectedTab={selectTabs}
          /> 
          <Template2CollectionContainer
            data={data?.data?.sectionWiseAssets}
            selectedTab={selectTabs}
            fullData={data}
          />
          <Template2Footer settings={settings}/>
     
        </>
      ) : null}
    </>
  );
};

