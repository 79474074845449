import React, { useState, useEffect } from "react";
//Video Player
import ReactPlayer from "react-player";

import { RiCloseCircleLine } from "react-icons/ri";


// Redux
import { useDispatch } from "react-redux";
import { updateState } from "../../../../../store/slice/state";
import { useSelector } from "react-redux";

const Template1VideoPlayer = ({ data }) => {
  const { selectedProductVideoID, productVideoPlaying } = useSelector(
    (state) => state.state
  );

  const globalDispatch = useDispatch();
  const [video, setVideo] = useState(null);
  const [videoPlaying, setVideoPlaying] = useState(true);
  // useEffect(()=>{
  //   setVideoPlaying(true)
  // },[video])
  useEffect(() => {
    let currentVideo = data.filter((item) => {
      return item.id == selectedProductVideoID;
    });
    setVideo(currentVideo);

    setVideoPlaying(true);
  }, [selectedProductVideoID, videoPlaying]);

  useEffect(() => {
    if (productVideoPlaying === true) {
      setVideoPlaying(true);
    } else {
      setVideoPlaying(false);
    }
  }, [productVideoPlaying]);

  // useEffect(()=>{
  //  // setVideoPlaying(true)
  // },[videoPlaying])

  const closeVideoPlayer = () => {
    globalDispatch(updateState({ productVideoPlaying: false }));
    setVideoPlaying(false);
    document.getElementsByClassName(
      "sections-wrapper-product-temp1"
    )[0].style.display = "flex";
    document.getElementsByClassName(
      "temp1-prod-content__top-section"
    )[0].style.display = "flex";
    document.getElementById("react-video-player-wrapper").style.display =
      "none";
    //  document.getElementById("react-video-player-wrapper").nextElementSibling.style.display="block"
    document.getElementsByClassName(
      "temp1-prod-content--wrapper"
    )[0].style.display = "flex";
    // setVideo(null)
  };
  return (
    <div className="player-wrapper" id="react-video-player-wrapper">
      {video !== null && (
        <ReactPlayer
          id="react-video-player-component"
          className="react-player"
          url={video[0]?.path}
          width="90%"
          height="100%"
          controls={true}
          playing={videoPlaying}
        />
      )}

      <div className="close-model-player-btn">
        {" "}
        <RiCloseCircleLine onClick={() => closeVideoPlayer()} />
      </div>
    </div>
  );
};

export default Template1VideoPlayer;
