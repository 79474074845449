import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';

export const Template3MobilePopup = ( { viewSectionList, setViewSectionList ,setViewName,setDisplayAsset} ) =>
{
  const { sectionTitle, assetList ,sectionThumbnail} = viewSectionList;
  return (
    <>
      <div className='temp3-popup__background'>
        <div className='temp3-popup__wrapper'>
          {sectionThumbnail !== null && sectionThumbnail !== '' ? <div className='temp3-popup__logo-box'>
            <img alt="section thumbnail"
                    className="temp3-mobile__section__logo"
                    src={sectionThumbnail }/>
          </div>:null}
          <div className='temp3-popup__close-box'>
            <AiOutlineClose className="custom-button--color-primary custom-button--w-15" onClick={ () => { setViewSectionList( { status: false, assetList: [], sectionTitle: null } ); } } style={{cursor:'pointer'}}/>
          </div>
          { sectionTitle !== null ? <div className='temp3-popup__title-box'>
            <h2 className='temp3-popup__title'>{ sectionTitle } </h2>
          </div> : null }
          <div className='temp3-popup__asset-list'>
            { assetList.length > 0 ? (
              <>
                { assetList.map( ( asset, index ) =>
                {
                  return (
                    <>
                      <div className='temp3-popup__asset' onClick={()=>{setDisplayAsset(asset);   
                          setViewName('assets')}}>
                        <p className='temp3-popup__asset-title'>{asset?.title}</p>
                        <BsArrowRight className="custom-button--color-dark custom-button--w-12" />
                      </div>
                    </>
                  );
                } ) }
              </>
            )
              : null }

          </div>
        </div>

      </div>

    </>
  );
};
