import React from "react";

export const Template1Footer = ({ settings }) => {
  return (
    <>
      {settings?.isPoweredByLogoEnabled === 'Yes' ? (
        <div className="temp1-footer-logo-sec"><div className="temp1-footer-img">
          <a
            href={
              "https://www.veespaces.com/?utm_source=client-website&utm_medium=created-by-logo-collection&utm_campaign=created-by-veespaces-logo"
            }
            target="_blank"
          >
            <img
              width="auto"
              height="auto"
              style={{ maxWidth: "100px", maxHeight: "50px" }}
              src={'https://space.veespaces.com/PoweredLogo.png'}
              alt="logo"
            />
          </a>
        </div></div>) : (null)}

    </>
  );
};



