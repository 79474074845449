import React from "react";
import nodata from "../../assets/nodata.png"
export const NotFoundError = () => {
  return (
    <>
    <div style={{display:"flex", justifyContent:"center",alignItems:"center",flexDirection:"column",}}>
       <img style={{width:"40%"}} src={nodata}/>
       <p style={{fontSize:"20px", marginBottom:"20px"}}>Oops... The link you clicked may be broken or the page may have been removed. We’re sorry.</p>
    </div>
    </>
  );
};
