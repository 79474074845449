import React, { useEffect, useState } from "react";
import { DocumentView } from "../../../../shared/DocumentView";
import { DownloadDocument } from "../../../../../utils/functions";
import { FaDownload } from "react-icons/fa";

import { useLeadFeature } from "../../../../../utils/hooks";
//Redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateState } from "../../../../../store/slice/state";

export const Template2DocumentContent = ({
  title,
  description,
  sourceURL,
  allowDownload,
  isCaptureLeadBeforeDownloadingTheAsset,
  data,
}) => {
  const { isLeadSent, isLeadCapturedForDownloadingAsset } = useSelector(
    (state) => state.state
  );
  const globalDispatch = useDispatch();

  const [features, setFeatures] = useState({
    isLeadActive: {
      from:
        isLeadSent === false && isCaptureLeadBeforeDownloadingTheAsset === "Yes"
          ? "PAGE"
          : "AFTER-SHARE",
      value:
        isLeadSent === false && isCaptureLeadBeforeDownloadingTheAsset === "Yes"
          ? true
          : false,
    },
  });
  const [downloadAsset, setDownloadAsset] = useState(null);
  const { LeadFeatureDesign } = useLeadFeature({
    exhibitorID: data,
    changeState: setFeatures,
    isActive: downloadAsset,
  });
  const [isDownloadBtnClicked, setIsDownloadBtnClicked] = useState(null);
  let downloadCheckInterval;
  const downloadAssetFn = () => {
    setIsDownloadBtnClicked(true);
    if (isLeadSent === false) {
      setDownloadAsset(features?.isLeadActive?.value);
      downloadCheckInterval = setInterval(checkAssetDownloadFn, 1000);

      if (isCaptureLeadBeforeDownloadingTheAsset === "No") {
        DownloadDocument(sourceURL,title)
      }
    }
    if (isLeadSent === true) {
      DownloadDocument(sourceURL,title)
    }
  };
  function checkAssetDownloadFn() {
    if (isLeadCapturedForDownloadingAsset === true && isDownloadBtnClicked === true) {
      DownloadDocument(sourceURL,title)
      globalDispatch(updateState({ isLeadCapturedForDownloadingAsset: false }));
      clearInterval(downloadCheckInterval);
      setIsDownloadBtnClicked(false);
    }
    clearInterval(downloadCheckInterval);
  }
  useEffect(() => {
    downloadCheckInterval = setInterval(checkAssetDownloadFn, 1000);
  }, [isLeadCapturedForDownloadingAsset]);

  useEffect(() => {
    if (isLeadSent === true) {
      //   DownloadDocument(sourceURL,title)
      setDownloadAsset(features?.isLeadActive?.value);
    }
  }, [features?.isLeadActive?.value]);
  return (
    <>
      <section className="content--wrapper temp1-doc1-content-wrapper">
        <div className="content--inner-wrapper temp1-doc1-inner-wrapper">
          <div className="temp1-title-box">
            {" "}
            <h2 className="asset-typo--title">{title}</h2>{" "}
            {allowDownload == "Yes" ? (
              <FaDownload
                className="custom-button__round--primary custom-button--w-20 custom-button"
                onClick={() => {
                  downloadAssetFn();
                }}
              />
            ) : null}
          </div>
          <div
            className="asset-typo--description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
          <div className="custom-video-player" style={{ display: "block" }}>
            <DocumentView URL={sourceURL} />
          </div>
        </div>
      </section>
      <LeadFeatureDesign />
    </>
  );
};
