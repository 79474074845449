import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BsShareFill, BsFillChatLeftTextFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";

//Redux
import { useDispatch } from "react-redux";
import { updateState } from "../../../../store/slice/state";

//React-Icons
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaInfoCircle } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";


export const Template1AssetHeader = ({ logoURL, settings, setFeatures, goBack, data, isDownloadBtnClickedFn }) => {
  const navigate = useNavigate();
  const globalDispatch = useDispatch();
  const showHideDescription = () => {
    document
      .getElementsByClassName("temp1-asset-header-desc")[0]
      .classList.toggle("temp1-asset-desc-show");
  };
  return (
    <>
      <header className="header asset-header">
        <div className="header__navigate-box">

          {typeof goBack.value !== "undefined" && goBack.value ? (
            <button
              id="go_back_button"
              className="custom-button__icon custom-button__icon--variant-no-border custom-button custom-button--border-none custom-button--transparent"
              onClick={() => {
                navigate(`/${goBack.url}`);
              }}
            >
              <AiOutlineArrowLeft className="custom-button--w-20" />
            </button>
          ) : null}
          {typeof logoURL !== "undefined" &&
            logoURL !== null &&
            logoURL !== "" ? (
            <div className="header__logo-box">
              <img className="header__logo rounded" src={logoURL} alt="Logo" />
            </div>
          ) : null}


          {typeof data?.asset_details?.title !== "undefined" &&
            data?.asset_details?.title !== null &&
            data?.asset_details?.title !== "" ? (<h4 className="asset-header-title">{data?.asset_details?.title}</h4>) : (<h4 className="asset-header-title">{data?.virtualspaceTitle}</h4>)}


          {/* {typeof data?.asset_details?.description !== "undefined" &&
            data?.asset_details?.description !== null &&
            data?.asset_details?.description !== "" ? (
            <div className="temp1-asset-header-show-desc-btn" ><div><FaInfoCircle onClick={() => showHideDescription()} />  </div>
              <div className="temp1-asset-header-desc">
                <IoCloseCircleOutline onClick={() => showHideDescription()} />

                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.asset_details?.description,
                  }}
                ></div>
              </div>
            </div>) : (null)} */}


        </div>

        <div className="header__features-box asset-header-options">
          {/* {data?.settings?.allowDownload === 'Yes' ? (<button className="custom-button custom-button__round custom-button--border-primary custom-button--w-30 custom-button--transparent"><FaDownload
            className="custom-button__round--primary custom-button--w-12 custom-button"
            onClick={() => {
              isDownloadBtnClickedFn(true);
            }}
          /></button>) : (null)} */}

          {settings?.isShareFormEnabled === "Yes" ? (
            <button
              id="email_share_button"
              className="custom-button custom-button__round custom-button--border-primary custom-button--w-30 custom-button--transparent"
              onClick={() => {
                setFeatures((prevState) => ({
                  ...prevState,
                  isShareActive: true,
                }));
              }}
            >
              <MdEmail className="custom-button__round--primary custom-button--w-12" />
            </button>
          ) : null}
          {settings?.isContactUsFormEnabled === "Yes" ? (
            <button
              id="contact_us_button"
              className="custom-button custom-button__round custom-button--border-primary custom-button--w-30 custom-button--transparent"
              onClick={() => {
                setFeatures((prevState) => ({
                  ...prevState,
                  isContactUsActive: true,
                }));
              }}
            >
              <BsFillChatLeftTextFill className="custom-button__round--primary custom-button--w-12" />
            </button>
          ) : null}
          {settings?.isSocialSharingButtonEnabled === "Yes" ? (
            <button
              id="social_share_button"
              className="custom-button custom-button__round custom-button--border-primary custom-button--w-30 custom-button--transparent"
              onClick={() => {
                if (navigator.share) {
                  navigator.share({
                    title: `${document.title}`,
                    url: window.location.href,
                    text: `${document.title}`,
                  });
                } else {
                  globalDispatch(updateState({ isSocialShareOpen: true }));
                }
              }}
            >
              <BsShareFill className="custom-button__round--primary custom-button--w-12" />
            </button>
          ) : null}

        </div>
      </header>
    </>
  );
};

// onClick={async () => {
//   if (navigator.share) {
//     navigator.share({
//       title:
//         `${document.title}`,
//       url: window.location.href
//       ,
//       text: `${document.title}`,
//     });
//   }}}
