import React, { useEffect, useState } from "react";
// import { TemplateData } from "../../../../../store/api";
// import { useAPIData } from "../../../../../utils/hooks";
import { Template5VideoAlbumHeader } from "./Template5VideoAlbumHeader";
import { Template5VideoAlbumFooter } from "./Template5VideoAlbumFooter";
import { Template5VideoAlbumBanner } from "./Template5VideoAlbumBanner";
import Template5VideoAlbumTabs from "./Template5VideoAlbumTabs";
import Template5VideoAlbumTabContent from "./Template5VideoAlbumTabContent";

export const Template5VideoAlbum = ({ data, settings, setFeatures }) => {
  const [selectTabs, setSelectTabs] = useState("");

  useEffect(() => {
    const firstTab = data?.data?.sections[0].sectionTitle;
    setSelectTabs(firstTab);
// console.log(firstTab,"ft");
  }, [data]);

  const tabsChange = (e) => {
    setSelectTabs(e);
  };
  return (
    <>
      {typeof data !== "undefined" ? (
        <>
          <Template5VideoAlbumHeader 
            logoURL={data?.branding?.collectionLogo}
            settings={settings}
            setFeatures={setFeatures}
            goBack={{ value: false, url: "" }}
          />
          <Template5VideoAlbumBanner data={data?.branding} />
<Template5VideoAlbumTabs data={data?.data?.sections}
            tabsChange={tabsChange}
            selectedTab={selectTabs}/>
            <Template5VideoAlbumTabContent  data={data?.data?.sectionWiseAssets}
            selectedTab={selectTabs}/>
          <Template5VideoAlbumFooter settings={settings} />
        </> 
      ) : null}
    </>
  );
};
