import React, { useEffect, useState, useRef } from "react";
import { Container, Box } from "@mui/material";
import Template1CollectionBanner from "./Template1CollectionBanner";
import Template1CollectioTabs from "./Template1CollectionTabs";
import Template1CollectionContainer from "./Template1CollectionContainer";
import { Template1Header } from "../Template1Header";
import { Template1Footer } from "../Template1Footer";

 const Template1Collection = ({ data, setFeatures, settings }) => {
  const [selectTabs, setSelectTabs] = useState("");
  const bannerRef = useRef(null);
  const [bannerHeight, setBannerHeight] = useState(0);

  useEffect(() => {
    const firstTab = data?.data?.sections[0]?.sectionTitle;
    setSelectTabs(firstTab);
  }, [data]);

  useEffect(() => {
    if (bannerRef.current) {
      setBannerHeight(bannerRef.current.clientHeight);
    }
  }, [bannerRef.current?.clientHeight]);

  const tabsChange = (e) => {
    setSelectTabs(e);
  };

  useEffect(() => {
    // Set the background color of the body
    document.body.style.backgroundColor = "#2D3748";

    // Cleanup function to reset the background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  return (
    <div style={{ background: "#2D3748", minHeight: "100vh" }}>
      {typeof data !== "undefined" ? (
        <Box>
          <Template1Header
            logoURL={data?.branding?.collectionLogo}
            settings={settings}
            setFeatures={setFeatures}
            goBack={{ value: false, url: "" }}
          />
          <Box ref={bannerRef} sx={{ position: "relative" }}>
            <Template1CollectionBanner data={data?.branding} />
            <Box
              sx={{
                position: "absolute",
                top: `${bannerHeight - 35}px`,
                left: "50%",
                transform: "translateX(-50%)",
               
              }}
              className="w-100"
            >
              <Container
                sx={{
                  backgroundColor: "#1a202c",
                  borderRadius: "8px",
               
                 
                }}
                className="w-100 mb-5"
              >
                <Template1CollectioTabs
                  data={data?.data?.sections}
                  tabsChange={tabsChange}
                  selectedTab={selectTabs}
                  branding={data?.branding}
                />
                <Template1CollectionContainer
                  data={data?.data?.sectionWiseAssets}
                  selectedTab={selectTabs}
                  fullData={data}
                />
              </Container>
              <Template1Footer settings={settings} className="w-100"/>
            </Box>
          </Box>
        </Box>
      ) : null}
    </div>
  );
};
export default Template1Collection