import React, { useState } from "react";
import Template1ProductTabs from "./Template1ProductTabs";
import Template1ProductTabContent from "./Template1ProductTabContent";
import { Template1Footer } from "../Template1Footer";


import { Template1AssetFooter } from "../Template1AssetFooter";

const Template1ProductAssets = ({ data,settings }) => {
  const [selectTabs, setSelectTabs] = useState("");
  const tabsChange = (e) => {
    setSelectTabs(e);
  };
  return (
    <div>
      <Template1ProductTabs
        data={data}
        selectedTab={selectTabs}
        tabsChange={tabsChange}
      />
      <Template1ProductTabContent data={data} selectedTab={selectTabs} />
      <Template1AssetFooter settings={settings}/>
    </div>
  );
};

export default Template1ProductAssets;
