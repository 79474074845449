import React from "react";
import { Template4Header } from "../Template4Header";
import { Template4Footer } from "../Template4Footer";

import { Template4AssetFooter } from "../Template4AssetFooter";

import { Template4ImageContent } from "./Template4ImageContent";

export const Template4Image = ({data, setFeatures, settings, goBack}) => {
  //console.log(data,"img");
  return (  
    <>  
    <div className="page" style={{ height: "auto", gap: "10px" }}>
       <Template4Header logoURL={data?.logo} setFeatures={setFeatures} settings={settings} goBack={goBack}/>
       <Template4ImageContent title={data?.asset_details.title} description={data?.asset_details.description} sourceURL={data?.asset_details.source_url} allowDownload={data?.settings?.allowDownload}  isCaptureLeadBeforeDownloadingTheAsset={
            data?.settings?.isCaptureLeadBeforeDownloadingTheAsset
          }
          data={data?.exhibitor_id}/>
    <Template4AssetFooter settings={settings}/>
    </div>
    </>
  );
};
