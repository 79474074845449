import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";

const ModalWrapper = ({children,setState}) => {
  return (
    <>
    <div className="modal-pop-overall">
        <div className="modal-pop-content">
          <div className="modal-close-tab">
            <AiFillCloseCircle
            className="custom-button custom-button--color-primary custom-button__round custom-button__round--primary custom-button--w-20"
              onClick={() => {
                setState(false);
              }}
            />
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default ModalWrapper;
