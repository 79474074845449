import React from "react";
import { Template2Footer } from "../Template2Footer";  
export const Template2PageContent = ({title,description,settings}) => {
  return (
    <>
     <section className="content--wrapper pages-wrapper">
        <div className="content--inner-wrapper content-pages-inner-wrapper">
           <h2 className="asset-typo--title">{title}</h2>
           <div className="asset-typo--description" dangerouslySetInnerHTML={{__html:description}}></div>
        </div>
   
    </section>
    </>
  );
};
