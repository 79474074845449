import React from "react";
import { useWindowSize } from "../../../../utils/hooks";
import { Template3MobileGuide } from "./template3Mobile/Template3MobileGuide";
import { Template3DesktopGuide } from "./template3Desktop/Template3DesktopGuide";

export const Template3GuideBook = ({ data ,settings,setFeatures}) => {
  const { size } = useWindowSize();
  return (
    <>
      <div className="temp3-page-overall">
        {size.width < 1024 ? (
          <Template3MobileGuide data={data} settings={settings} setFeatures={setFeatures}/>
        ) : (
          <Template3DesktopGuide data={data} settings={settings} setFeatures={setFeatures}/>
        )}
      </div>
    </>
  );
};
