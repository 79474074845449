import { DocumentView } from "../components/shared/DocumentView";
import { VideoPlayer } from "../components/shared/VideoPlayer";

export const chooseGuideBookContent = (type, src, description) => {
  // console.log(src);

  switch (type) {
    case "videos":
      return <VideoPlayer videoURL={src} />;
    case "images":
      return (
        <img
          src={src}
          style={{ width: "100%", height: "600px" }}
          width={"100%"}
          alt="content"
          height={"600px"}
        />
      );
    case "links":
      return (
        <iframe src={src} height={600} width={"100%"} title="Link content" />
      );
    case "pages":
      return (
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          style={{ width: "100%", overflowY: "auto" }}
        ></div>
      );
    case "audios":
      return (
        <audio controls src={src} width={"100%"} controlsList="nodownload" />
      );
    case "documents":
      return (
        <div style={{ height: "600px", minHeight: "8rem", width: "100%" }}>
          <DocumentView URL={src} />
        </div>
      );
      case "products":
        return (
          <img
          src={src}
          style={{ width: "100%", height: "600px" }}
          width={"100%"}
          alt="content"
          height={"600px"}
        />
        );
    case "3d":
      return (
        <div style={{ height: "50vh", minHeight: "8rem", width: "100%" }}>
          {" "}
          <model-viewer
            style={{ width: "100%", height: "100%", borderRadius: "1rem" }}
            alt="Source 3D Model"
            src={src}
            ar
            ar-modes="scene-viewer quick-look webxr"
            environment-image=""
            poster=""
            shadow-intensity="1"
            camera-controls
            touch-action="pan-y"
            xr-environment
          ></model-viewer>
        </div>
      );
    default:
      return null;
  }
};
