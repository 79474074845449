import { useCallback, useMemo, useRef } from "react";
import { useReducer } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  ContactUsAPI,
  getCountryCodes,
  getEmailTemplate,
  getLeadsManagement,
  sendResourcesEmail,
} from "../store/api";
import { useForm } from "react-hook-form";
import { AiOutlineCloseCircle } from "react-icons/ai";
import EmailEditor from "../components/shared/EmailEditor";
import loader from "../assets/loader.gif";
import { DownloadDocument, DownloadImage } from "./functions";
import { updateState } from "../store/slice/state";
import { MdGroup } from "react-icons/md";
import { LeadFeature } from "../components/shared/LeadFeature";
import { ContactUsFeature } from "../components/shared/ContactUsFeature";
import { ShareFeature } from "../components/shared/ShareFeature";
import { ProductEnquiry } from "../components/shared/ProductEnquiry";

export const useAPIData = (API, payload) => {
  const [data, setData] = useState();

  useEffect(() => {
    (async function (payload) {
      await API(payload).then((res) => {
        setData(res);
      });
    })(payload);
  }, []);

  return { data };
};

export const useWindowSize = () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    window.addEventListener("resize", () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });

    return () => {
      window.removeEventListener("resize", () => {
        setSize({
          width: 0,
          height: 0,
        });
      });
    };
  }, []);

  return { size };
};

export const useMasonryLayout = (columnCount, data) => {
  const [columnWiseData, setColumnWiseData] = useState({});

  //console.log(data,"hooks");
  useEffect(() => {
    // columnsWrapper holding all columns
    let columnsWrapper = {};

    // Creating four columns with empty array inside columnsWrapper
    for (let i = 0; i < columnCount; i++) {
      columnsWrapper[`column${i}`] = [];
    }
    //Pushing items equally inside each column array inside columnsWrapper
    for (let i = 0; i < data?.length; i++) {
      let assignColumn = i % columnCount;
      columnsWrapper[`column${assignColumn}`].push(data[i]);
      //console.log(columnsWrapper,"colwrap");
    }

    setColumnWiseData(columnsWrapper);
  }, [data, columnCount]);

  return { columnWiseData };
};

export const useShareFeature = ({
  isActive,
  changeState,
  uid,
  collectionURL,
  emailConfig,
}) => {
  const ShareFeatureDesign = useCallback(() => {
    return (
      <>
        {isActive && (
          <ShareFeature
            uid={uid}
            changeState={changeState}
            emailConfig={emailConfig}
            collectionURL={collectionURL}
          />
        )}
      </>
    );
  }, [isActive]);

  return { ShareFeatureDesign };
};

export const useContactUsFeature = ({ uid, changeState, isActive }) => {
  const [countryCode, setCountryCode] = useState(null);

  const ContactUsFeatureDesign = useCallback(() => {
    return (
      <>
        {isActive && (
          <ContactUsFeature
            uid={uid}
            changeState={changeState}
            countryCodes={countryCode}
          />
        )}
      </>
    );
  }, [isActive]);

  useEffect(() => {
    getCountryCodes().then((res) => {
      setCountryCode(res);
    });
  }, []);

  return { ContactUsFeatureDesign };
};

export const useLeadFeature = ({ exhibitorID, changeState, isActive ,collectionID}) => {
  const [countryCode, setCountryCode] = useState(null);

  const LeadFeatureDesign = useCallback(() => {
    return (
      <>
        {isActive && (
          <LeadFeature
            countryCodes={countryCode}
            exhibitorID={exhibitorID}
            changeState={changeState}
            collectionID={collectionID}
          />
        )}
      </>
    );
  }, [countryCode, isActive]);

  useEffect(() => {
    getCountryCodes().then((res) => {
      setCountryCode(res);
    });
  }, []);

  return { LeadFeatureDesign };
};

export const useProductEnquiry = ({ apiPayload, isActive, setChange }) => {
  const [countryCode, setCountryCode] = useState(null);

  const ProductEnquiryDesign = useCallback(() => {
    return (
      <>
        {isActive && (
          <ProductEnquiry
            countryCodes={countryCode}
            title="Enquiry"
            apiPayload={apiPayload}
            setChange={setChange}
          />
        )}
      </>
    );
  }, [isActive]);

  useEffect(() => {
    getCountryCodes().then((res) => {
      setCountryCode(res);
    });
  }, []);

  return { ProductEnquiryDesign };
};
