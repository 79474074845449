import React from 'react';
import { useState } from 'react';
import { Tabs, Tab, ThemeProvider, createTheme } from '@mui/material';

const Template1CollectioTabs = ({ data, selectedTab, tabsChange ,branding}) => {
  const handleTabChange = (event, newValue) => {
    tabsChange(newValue);
  };
console.log(data,"fffffffffffffffff")
  // Create a custom theme with the accent color from data
  const customTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: branding?.accentColors?.primary || '#ffffff', // default to white if not provided
      },
      text: {
        primary: branding?.accentColors?.primary || '#ffffff', // default to white if not provided
      },
    },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <div className="rounded">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          allowScrollButtonsMobile
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          // TabScrollButtonProps={{
          //   sx: {
          //     color: `${branding?.accentColors?.primary || '#ffffff'}`,
          //    padding:"0px",
          //    fontSize:"10px"
          //   },
          // }}
          TabScrollButtonProps={{
            sx: {
              '&.MuiTabScrollButton-root': {
                '& svg': {
                  color: `${branding?.accentColors?.primary || '#ffffff'}`, // Set your desired color here
                  fontSize: '2rem', // Adjust the size as needed
                },
              },
            },
          }}
        >
          {data?.map((element, index) => (
            <Tab
              key={index}
              label={element.sectionTitle}
              value={element.sectionTitle}
            />
          ))}
        </Tabs>
      </div>
    </ThemeProvider>
  );
};

export default Template1CollectioTabs;
