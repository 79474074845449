import axios from "axios";
import qs from "qs";
import { Api_Key } from "../App";
var CryptoJS = require("crypto-js");

const apiRootPath = process.env.REACT_APP_API_ROOT_PATH;
const apiAppRootPath = process.env.REACT_APP_API_APP_ROOT_PATH;
const API_SECRET_TOKEN = process.env.REACT_APP_API_SECRET_TOKEN;

//<------------------------------ CONFIG FUNCTION FOR API CALL -------------------------->
const getConfig = (url, method, contentType, data, sendHeader = true) => {
  if (sendHeader) {
    return {
      url: url,
      method: method,
      headers: {
        "Content-Type": contentType,
        'x-api-key': API_SECRET_TOKEN,
        'Access-Control-Allow-Origin': '*',
      },
      data: data,
    };
  } else {
    return {
      url: url,
      method: method,
      headers: {
        'x-api-key': API_SECRET_TOKEN,
        'Access-Control-Allow-Origin': '*',
      },
      data: data,
    };
  }
};
const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

//<------------------------------------- ENCRYPT FUNCTION ----------------------------------------->
const Encrypt_payload = async (payload, unique_key) => {
  let encrypt_payload = {
    payload,
  };

  let course_encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(encrypt_payload),
    unique_key
  );
  let encrypt_json = {
    iv: course_encrypted.iv.toString(),
    s: course_encrypted.salt.toString(),
    ct: course_encrypted.ciphertext.toString(CryptoJS.enc.Base64),
  };

  let encrypt_json_string = {
    value: { encrypt_payload_string: JSON.stringify(encrypt_json) },
  };

  return encrypt_json_string;
};
//<---------------------------------- DECRYPT FUNCTION ------------------------------------------------>
const Decrypt_response = async (response, unique_key) => {
  let response_data = "";

  if (
    response?.data?.status.localeCompare("success") == 0 &&
    !response?.message
  ) {
    let salt = response?.data?.salt;
    let iv = response?.data?.iv;
    let iterations = response?.data?.iterations;
    let passphrase = unique_key;

    let key = CryptoJS.PBKDF2(passphrase, salt, {
      hasher: CryptoJS.algo.SHA256,
      keySize: 64 / 8,
      iterations: iterations,
    });
    let decrypted = CryptoJS.AES.decrypt(response?.data?.data, key, {
      iv: CryptoJS.enc.Utf8.parse(iv),
    });
    let decrypt_data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    response_data = { value: { decrypt_data } };
    return response_data;
  }
};  

const shouldISendHeader = false;


/*---------------------------------------Web Analytics API----------------------------------------*/
export const boothvisitor = async (payload) => {
  const response = await axios(
    getConfig(
      apiRootPath + "wa-tracking" , //api
      "post",
      "application/json",
      JSON.stringify(payload),
      true
    )
  )
    .then(async (response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
  sessionStorage.setItem("res", response);
  window.localStorage.setItem("count", response);

  return response;
};
/*---------------------------------------Web Analytics API----------------------------------------*/

export const sendProductEnquiryEmail = async (payload) => {
  let unique_key = Api_Key;
  let Encrypted_payload;
  let Decrypted_response;

  Encrypted_payload = (await Encrypt_payload(payload, unique_key)).value;
  let response = await axios(
    getConfig(
      apiRootPath + "forms/product-enquiry" , //api
      "post",
      "application/json",
      JSON.stringify(Encrypted_payload),
      shouldISendHeader
    )
  )
    .then(async (response) => {
      // console.log("enquiryEmailData", response?.data);
      Decrypted_response = (await Decrypt_response(response, unique_key)).value;
      return Decrypted_response?.decrypt_data;
    })
    .catch((error) => {
      // console.log(error);
    });
  return response;
};

export const getProductsDetails = async (payload) => {
  // let unique_key = Api_Key;
  // let Encrypted_payload;
  // let Decrypted_response;

  // Encrypted_payload = (await Encrypt_payload(payload, unique_key)).value;
  const response = await axios(
    getConfig(
      apiRootPath + "/product-detail" , //api
      "post",
      "application/json",
      JSON.stringify(payload),
      shouldISendHeader
    )
  )
    .then(async (response) => {
      // Decrypted_response = (await Decrypt_response(response, unique_key)).value;
      //  console.log("ProductsDetails",Decrypted_response?.decrypt_data?.data)
      // return Decrypted_response?.decrypt_data?.data;
      return response?.data;
    })
    .catch((error) => {
      //console.log(error);
      return error;
    });

  return response;
};

export const getEmailTemplate = async (payload) => {
  const response = await axios(
    getConfig(
      apiRootPath + `get-email-template` , //api
      "post",
      "multipart/form-data",
      payload,
      shouldISendHeader
    )
  )
    .then(async (response) => {
      return response?.data?.data;
    })
    .catch((error) => {
      // console.log("countryCodes error", error);
    });
  return response;
};

export const getCountryCodes = async (payload) => {
  let unique_key = Api_Key;
  let Encrypted_payload;
  let Decrypted_response;
  Encrypted_payload = (await Encrypt_payload(payload, unique_key)).value;
  // console.log("CountryCodes",Encrypted_payload)
  const response = await axios(
    getConfig(
      apiRootPath + "get-phone-number-country-code" , //api
      "post",
      "application.json",
      "",
      shouldISendHeader

    )
  )
    .then(async (response) => {
      Decrypted_response = (await Decrypt_response(response, unique_key)).value;
      //  console.log(Decrypted_response?.decrypt_data);
      // console.log("CountryCodes",Decrypted_response?.decrypt_data)
      return Decrypted_response?.decrypt_data;
    })
    .catch((error) => {
      // console.log("countryCodes error", error);
    });
  return response;
};

export const sendResourcesEmail = async (payload) => {
  
  let response = await axios(
    getConfig(
      apiRootPath + "send-email-preview" , //api
      "post",
      "multipart/form-data",
      payload,
      shouldISendHeader
    )
  )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {});

  return response;
};

export const getLeadsManagement = async (payload) => {
  let unique_key = Api_Key;
  let Encrypted_payload;
  let Decrypted_response;
  Encrypted_payload = (await Encrypt_payload(payload, unique_key)).value;
  // console.log(Encrypted_payload)
  let response = await axios(
    getConfig(
      apiRootPath + "contact/add" , //api
      "post",
      "application/json",
      payload,
      shouldISendHeader
    )
  )
    .then((response) => {
      // console.log(response?.data?.status);

      return response;
    })
    .catch((error) => {
      // console.log(error);
    });
  return response;
};

export const ViewCount = async (payload) => {
  // console.log( payload ,"qrscan");
  const response = await axios(
    getConfig(
      apiRootPath + "qr-scan" , //api
      "post",
      "application/json",
      JSON.stringify(payload),
      true

    )
  )
    .then(async (response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });

  return response?.data;
};

export const ContactUsAPI = async (payload) => {
  let unique_key = Api_Key;
  let Encrypted_payload;
  let Decrypted_response;
  Encrypted_payload = (await Encrypt_payload(payload, unique_key)).value;

  const response = await axios(
    getConfig(
      apiRootPath + "forms/contact-us" , //api
      "post",
      "application/json",
      JSON.stringify(Encrypted_payload),
      shouldISendHeader
    )
  )
    .then(async (response) => {
      Decrypted_response = (await Decrypt_response(response, unique_key)).value;
      return Decrypted_response;
    })
    .catch((error) => {
      return error;
    });

  return response;
};

export const CollectionAsset = async (payload) => {
  //   let unique_key = Api_Key;
  //   let Encrypted_payload;
  //   let Decrypted_response;
  //   Encrypted_payload = (await Encrypt_payload(payload, unique_key)).value;

  const response = await axios(
    getConfig(
      apiAppRootPath + "get-asset-by-collection-asset-id" , //api
      "post",
      "application/json",
      JSON.stringify(payload),
      true
    )
   
  )
    .then(async (response) => {
      //   Decrypted_response = (await Decrypt_response(response, unique_key)).value;
      //   return Decrypted_response;
      return response;
    })
    .catch((error) => {
      return error;
    });

  return response;
};

export const TemplateData = async (payload) => {
  const response = await axios(
    getConfig(
      apiAppRootPath + "get-micropage-by-url" , //api
      "post",
      "application/json",
      JSON.stringify(payload),
      shouldISendHeader
    )
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

  return response;
};

export const CollectionAuthentication = async (payload) => {
  const response = await axios(
    getConfig(
      apiAppRootPath + "authentication" , //api
      "post",
      "application/json",
      JSON.stringify(payload),
      shouldISendHeader
    )
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

  return response;
};


export const AssetAuthentication = async (payload) => {
  const response = await axios(
    getConfig(
      apiAppRootPath + "asset-authentication" , //api
      "post",
      "application/json",
      JSON.stringify(payload),
      shouldISendHeader
    )
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

  return response;
};
