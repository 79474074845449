import React, { useState, useEffect } from "react";
import { RiCloseCircleLine } from "react-icons/ri";
import { useSelector } from "react-redux";

const Template2ProductModelViewer = ({ data }) => {
  const { selectedProductModelID } = useSelector((state) => state.state);
 // console.log(data,"idid");
  const [model, setModel] = useState(null);
  useEffect(() => {
    let currentModel = data.filter((item) => {
      return item['3d_object_id'] == selectedProductModelID;
    });
    setModel(currentModel);
  }, [selectedProductModelID]);

  const closeModelPlayer = () => {
    document.getElementsByClassName(
      "sections-wrapper-product-temp1"
    )[0].style.display = "flex";
    document.getElementsByClassName(
      "temp1-prod-content__top-section"
    )[0].style.display = "flex";
    document.getElementById("react-model-player-wrapper").style.display =
      "none";
    // document.getElementsByClassName(
    //   "temp1-prod-content__bottom-section"
    // )[0].style.display = "flex";
    document.getElementsByClassName(
        "temp1-prod-content--wrapper"
      )[0].style.display = "flex";
   // setModel(null);
  };
  return (
    <div className="player-wrapper" id="react-model-player-wrapper">
      {model !== null && (
        <model-viewer
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "1rem",
          }}
          alt="Source 3D Model"
          src={model?.[0]?.["3d_object_path"]}
          ar
          ar-modes="scene-viewer quick-look webxr"
          environment-image=""
          poster=""
          shadow-intensity="1"
          camera-controls
          touch-action="pan-y"
          xr-environment
        ></model-viewer>
      )}

      <div className="close-video-player-btn">
        {" "}
        <RiCloseCircleLine onClick={() => closeModelPlayer()} />
      </div>
    </div>
  );
};

export default Template2ProductModelViewer;
