import React from "react";
import { Template2Header } from "../Template2Header";
import { Template2Footer } from "../Template2Footer";
import { Template2AudioContent } from "./Template2AudioContent";
import { Template2AssetFooter } from "../Template2AssetFooter";

export const Template2Audio = ({data,setFeatures, settings, goBack}) => {
  return (
    <>
    <div className="page">
       <Template2Header logoURL={data?.logo} setFeatures={setFeatures} settings={settings} goBack={goBack}/>
       <Template2AudioContent title={data?.asset_details.title} description={data?.asset_details.description} sourceURL={data?.asset_details.source_url} allowDownload={data?.settings?.allowDownload}  isCaptureLeadBeforeDownloadingTheAsset={
            data?.settings?.isCaptureLeadBeforeDownloadingTheAsset
          } 
          data={data?.exhibitor_id}/>
   <Template2AssetFooter settings={settings}/>
    </div>
    </>
  );
};

