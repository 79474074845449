import React from 'react'
import { useEffect, useState } from "react";

const Template2ProductTabs = ({tabsChange,selectedTab,data}) => {
   const tabsdName=["Description","Images","Videos","Documents","3D models"]

   const [tabsName, setTabsName] = useState([]);
   useEffect(() => {
     let tabsNameArray = [];
     if (data?.product?.[0]?.product_description?.length !== 0) {
       tabsNameArray.push("Description");
     }
     if (data?.product_images?.length !== 0) {
       tabsNameArray.push("Images");
     }
     if (data?.product_videos?.length !== 0) {
       tabsNameArray.push("Videos");
     }
     if (data?.product_documents?.length !== 0) {
       tabsNameArray.push("Documents");
     }
     if (data?.product_models?.length !== 0) {
       tabsNameArray.push("3D models");
     }
     setTabsName([...tabsNameArray]);
     tabsChange(tabsNameArray[0]);
   }, [data]);
   const selectTab = (e) => {
    tabsChange(e.target.innerText);
  };
  return (
<div className="sections-wrapper-product-temp1">
        {tabsName?.map((element, index) => {
          return (
            <button
              key={index}
              className={`product-tab-button ${selectedTab === element? 'product-tab-active' : ''}`}
              onClick={(element) => selectTab(element)}
            >
              {element}
            </button>
          );
        })}
      </div>
   
  )
}

export default Template2ProductTabs