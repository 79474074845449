import React, { useEffect, useReducer, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import loader from '../../assets/loader.gif';
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getEmailTemplate, sendResourcesEmail } from "../../store/api";
import { AiOutlineCloseCircle } from "react-icons/ai";
import EmailEditor from "./EmailEditor";

export const ShareFeature = ({ uid, changeState, emailConfig }) => {
  const { emailInstanceID, sourceID, contentType, collectionURL } = emailConfig;
  const editorRef = useRef(null);
  console.log(emailConfig?.source_id, "wdwdwdwdw");
  //  console.log(sourceID,"source");
  const { isLeadSent } = useSelector((state) => state.state);
  const defaultFields = [
    {
      label: 'To',
      fieldFor: 'email',
      required: true,
      placeholder: 'Please enter the email to send',
      pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
      type: 'email',
      tag: 'input',
    },
    {
      label: 'Subject',
      fieldFor: 'subject',
      required: true,
      placeholder: 'Enter an subject',
      pattern: null,
      type: 'text',
      tag: 'input',
    },
  ];
  const status = {
    shareResponse: {
      state: false,
      message: '',
      severity: '',
    },
    loader: false,
    emailTemplate: {
      subject: '',
      body: '',
    }
  };
  const shareReducer = (state, action) => {
    switch (action.type) {
      case 'SHARE_API_RESPONSE': {
        if (action.state === false) {
          return {
            ...state,
            shareResponse: {
              state: action.state,
              message: '',
              severity: '',
            },
          };
        }
        if (action.state === true) {
          return {
            ...state,
            shareResponse: {
              state: action.state,
              message: action.message,
              severity: action.severity,
            },
          };
        }
      }
      case 'API_LOADER': {
        return {
          ...state,
          loader: action.state,
        };
      }
      case 'UPDATE_EMAIL_TEMPLATE': {
        return {
          ...state,
          emailTemplate: {
            subject: action.subject,
            body: action.body,
          }
        };
      }
      default:
        return state;
    }
  };
  const [shareState, shareDispatch] = useReducer(shareReducer, status);
  const values = {
    email: '',
    subject: shareState?.emailTemplate?.subject,
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      email: '',
      subject: '',
    },
    values,
  });


  useEffect(() => {
    reset({
      email: '',
      subject: '',
    });
  }, [isSubmitSuccessful]);
  async function sendEmail(dataEmail) {
    shareDispatch({ type: 'API_LOADER', state: true });
    let payload = new FormData();
    payload.append('uid', uid);
    payload.append('email_address', dataEmail?.email);
    payload.append('subject', dataEmail?.subject);
    payload.append('email_instance_id', emailInstanceID);

    if (emailConfig?.source_id !== null) {
      
      payload.append('source_id', emailConfig?.source_id);
    }

    if (contentType !== null) {
      payload.append('content_type', contentType);
    }
    payload.append(
      'body',
      editorRef.current ? editorRef.current.getContent() : ''
    );
console.log(payload,"emailPayload");
    await sendResourcesEmail(payload)
      .then((res) => {
        shareDispatch({ type: 'API_LOADER', state: false });
        //   console.log( res );
        if (res?.status.toLowerCase() === "success") {
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
        if (isLeadSent === true) {
          setTimeout(() => {
            changeState((prevState) => ({ ...prevState, isShareActive: false }));
          }, 2000);
        } else if (isLeadSent === false) {
          setTimeout(() => {
            changeState((prevState) => ({ ...prevState, isShareActive: false, isLeadActive: { from: 'AFTER-SHARE', value: true } }));
          }, 2000);
        }


      })
      .catch((err) => {
        // console.log(err);
      });
  }
  useEffect(() => {

    (async function () {
      let payload = new FormData();
      payload.append(
        'uid',
        uid
      );
      payload.append('email_instance_id', emailInstanceID); //17

      if (contentType !== null) {
        payload.append('content_type', contentType);
        payload.append('content_id', sourceID)

      } else {
        payload.append('collection_unique_string', collectionURL);
      }



      await getEmailTemplate(payload).then((res) => {
        if (
          res?.subject &&
          res?.body &&
          typeof res?.subject !== 'undefined' &&
          typeof res?.body !== 'undefined'
        ) {
          shareDispatch({
            type: 'UPDATE_EMAIL_TEMPLATE',
            subject: res?.subject,
            body: res?.body,
          });
        } else {
          shareDispatch({
            type: 'UPDATE_EMAIL_TEMPLATE',
            subject: 'preset subject here',
            body: '<p>Enter your text here</p>',
          });
        }
      });
    })();

  }, []);
  return (
    <>
      <div className="share-pop-overall">
        <div className="share-pop-content">
          <div className="share-pop-top-plane">
            <div className="close-icon-plane">
              <AiOutlineCloseCircle
                className="custom-button custom-button--color-primary custom-button__round custom-button__round--primary custom-button--w-20"
                onClick={(e) => {
                  changeState((prevState) => ({ ...prevState, isShareActive: false }));
                }}
              />
            </div>

            <div className="title-plane">
              <h3 className="title" >
                Compose
              </h3>

              <h4 className="secondary-title">Share content, quick and easy</h4>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(sendEmail)}
            className="share-pop-main-plane">
            {defaultFields?.map((field, index) => {
              return (
                <>
                  {field?.tag === 'input' ? (
                    <div className="input-field" key={index}>
                      <div className="input-block">
                        <label htmlFor={field?.fieldFor} id="input-label">
                          {field?.label.toUpperCase()}
                        </label>
                        <input
                          placeholder={field?.placeholder}
                          {...register(field?.fieldFor, {
                            required: field?.required,
                            pattern:
                              field?.pattern !== null ? field?.pattern : null,
                          })}
                          type={field?.type}
                          className={
                            errors?.[field?.fieldFor]
                              ? 'input-box-err'
                              : 'input-box'
                          }
                          id={field?.fieldFor}
                        />
                      </div>
                      <div className="err-block">
                        {errors?.[field?.fieldFor] &&
                          (errors?.[field?.fieldFor].type === 'required' ? (
                            <span
                              style={{
                                color: 'rgb(139, 139, 85)',
                                fontSize: '0.6rem',
                              }}>
                              This field is required !!
                            </span>
                          ) : errors?.[field?.fieldFor].type === 'pattern' ? (
                            <span
                              style={{
                                color: 'rgb(139, 139, 85)',
                                fontSize: '0.6rem',
                              }}>
                              Invalid input !!
                            </span>
                          ) : null)}
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })}
            <EmailEditor
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={shareState?.emailTemplate?.body}
              init={{
                height: 500,
                menubar: 'format',
                convert_urls: false,
                plugins: [
                  'advlist',
                  'anchor',
                  'autolink',
                  'help',
                  'image',
                  'link',
                  'lists',
                  'searchreplace',
                  'table',
                  'wordcount',
                ],
                toolbar:
                  'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
            <button className="custom-button custom-button__box custom-button--primary custom-button--color-white custom-button--border-none" type="submit" style={{ width: '100%' }} >
              <p style={{ margin: 0, marginRight: 6 }}>SEND</p>
              {shareState?.loader === true ? (
                <img src={loader} width="20px" height="20px" alt="loader"></img>
              ) : null}
            </button>
          </form>
          <hr></hr>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            limit={2}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </div>
      </div>
    </>
  );
};
