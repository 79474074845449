import React from "react";
import { Template1virtualspaceContent } from "./Template1virtualspaceContent";
import { Template1AssetHeader } from "../Template1AssetHeader";
import { Template1AssetFooter } from "../Template1AssetFooter";
import { useLocation } from "react-router-dom";

export const Template1virtualspace = ({ data, setFeatures, settings, goBack }) => {
  const location = useLocation();
  const { item,fullData } = location.state || {}; // Default to an empty object if state is undefined
  let virtual = {}
  virtual.virtualspaceTitle = item?.title
  return (
    <>
      <div className="page">
        <Template1AssetHeader data={virtual} logoURL={fullData?.branding?.collectionLogo} setFeatures={setFeatures} settings={settings} goBack={goBack} />
        <Template1virtualspaceContent title={item?.title} description={item?.description} tour_code={item?.assetUniqueUrl} />
        <Template1AssetFooter settings={settings} />
      </div>
    </>
  );
};
