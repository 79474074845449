import React, { useState, useEffect } from "react";
import { useParams, useLoaderData, Navigate } from "react-router-dom";
import { CollectionAsset } from "../store/api";
import { chooseAssetTemplate } from "../utils/chooseTemplate";
import {
  useContactUsFeature,
  useLeadFeature,
  useShareFeature,
} from "../utils/hooks";

import { AssetAuthentication } from "../store/api";

import { emailTemplateInstanceID } from "../utils/constants";
import { useSelector } from "react-redux";

//Components
import PasswordDialog from "../components/shared/PasswordDialog";
import FallbackSocial from "../components/shared/FallbackSocial";
import LocationTracker from "../utils/LocationTracker";

export const DirectRouteAssetPath = () => {
  const { assetURL } = useParams();
  const assetLoaderData = useLoaderData();
  let typeNo;
  const [isFlipBook, setIsFlipBook] = useState(false);
  let { isLeadSent, collectionID } = useSelector((state) => state.state);
  switch (assetLoaderData?.data?.asset_type) {
    case "documents":
      typeNo = 11;
      break;
    case "images":
      typeNo = 22;
      break;
    case "products":
      typeNo = 7;
      break;
    case "links":
      typeNo = 23;
      break;
    case "3D":
      typeNo = 24;
      break;
    case "pages":
      typeNo = 26;
      break;
    case "videos":
      typeNo = 12;
      break;
    case "audios":
      typeNo = 25;
      break;

    default:
  }

  const [isPasswordFalse, setIsPasswordFalse] = useState("");
  const [authPass, setAuthPass] = useState(true);

  useEffect(() => {
    if (assetLoaderData?.data?.visibility === "public") {
      setAuthPass(true);
    } else if (assetLoaderData?.data?.visibility === "protected") {
      checkSessionStorageFn();
    } else if (assetLoaderData?.data?.visibility === "private") {
      checkSessionStorageFn();
    }
    setAccentColorsFn();
  }, []);

  // session storage only for type protected and private
  const checkSessionStorageFn = () => {
    let cID = sessionStorage.getItem("authVerify");

    //  cID=JSON.parse(cID)
    //console.log(cID, "sesionStorage");

    let parsedData = JSON.parse(cID);

    if (
      parsedData?.id === assetLoaderData?.data?.collectionID &&
      parsedData?.pass === true
    ) {
      setAuthPass(true);
    } else {
      setAuthPass(false);
    }
  };
  const passwordSubmitFN = (e) => {
    let payload;
    if (assetLoaderData?.data?.visibility === "public") {
      payload = {
        asset_id: assetLoaderData?.data?.asset_id,
        uid: assetLoaderData?.data?.uid,
        type: assetLoaderData?.data?.visibility,
      };
    } else if (assetLoaderData?.data?.visibility === "protected") {
      payload = {
        asset_id: assetLoaderData?.data?.asset_id,
        uid: assetLoaderData?.data?.uid,
        type: assetLoaderData?.data?.visibility,
        password: e,
      };
    } else if (assetLoaderData?.data?.visibility === "private") {
      payload = {
        asset_id: assetLoaderData?.data?.asset_id,
        uid: assetLoaderData?.data?.uid,
        type: assetLoaderData?.data?.visibility,
        email: e,
      };
    }
    AssetAuthentication(payload)
      .then((res) => {
        if (res?.data?.status === "success") {
          setIsPasswordFalse(false);
          setAuthPass(true);
          let authVerify = {
            id: assetLoaderData?.data?.collectionID,
            pass: true,
          };
          sessionStorage.setItem("authVerify", JSON.stringify(authVerify));
          //  console.log("passed pas", collectionData?.data?.collectionID);
        } else if (res?.data?.status === "failed") {
          setIsPasswordFalse(true);
          setAuthPass(false);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const setAccentColorsFn = () => {
    const root = document.documentElement;
    root.style.setProperty(
      "--primary-color",
      assetLoaderData?.data?.branding?.accentColors.primary
    );
    root.style.setProperty(
      "--bs-primary",
      assetLoaderData?.data?.branding?.accentColors.primary
    );
    root.style.setProperty(
      "--secondary-color",
      assetLoaderData?.data?.branding?.accentColors.secondary
    );
    if (assetLoaderData?.data?.asset_type !== "products") {
      document.title = `${assetLoaderData?.data?.asset_details?.title}`;
    } else {
      document.title = `${assetLoaderData?.data?.product[0]?.product_title}`;
    }
  };

  // this if else is for deciding leadCapture form opening
  if (collectionID === assetLoaderData?.data?.asset_id && isLeadSent === true) {
    isLeadSent = true;
  } else {
    isLeadSent = false;
  }
  const [features, setFeatures] = useState({
    isContactUsActive: false,
    isShareActive: false,
    isLeadActive: {
      from:
        isLeadSent === false &&
        assetLoaderData?.data?.settings?.isCaptureLeadBeforeViewingTheAsset ===
          "Yes"
          ? "PAGE"
          : "AFTER-SHARE",
      value:
        isLeadSent === false &&
        assetLoaderData?.data?.settings?.isCaptureLeadBeforeViewingTheAsset ===
          "Yes"
          ? true
          : false,
    },
  });
  const { ShareFeatureDesign } = useShareFeature({
    isActive: features?.isShareActive,
    changeState: setFeatures,
    uid: assetLoaderData?.data?.uid,
    emailConfig: {
      emailInstanceID: emailTemplateInstanceID?.ASSET,
      sourceID: assetLoaderData?.data?.asset_id,
      contentType: assetLoaderData?.data?.asset_type,
    },
  });
  const { ContactUsFeatureDesign } = useContactUsFeature({
    uid: assetLoaderData?.data?.uid,
    changeState: setFeatures,
    isActive: features?.isContactUsActive,
  });
  const { LeadFeatureDesign } = useLeadFeature({
    exhibitorID: assetLoaderData?.data?.uid,
    changeState: setFeatures,
    isActive: features?.isLeadActive?.value,
    collectionID: assetLoaderData?.data?.asset_id,
  });
  if (assetLoaderData?.data?.status === "failed") {
    return <Navigate to="/404" replace={true} />;
  }
  let tempFlipBook;
  if (assetLoaderData?.data?.flipBook === "Yes") {
    tempFlipBook = "Yes";
  } else {
    tempFlipBook = "No";
  }

  return (
    <>
      {authPass !== true ? (
        <PasswordDialog
          passwordSubmitFN={passwordSubmitFN}
          isPasswordFalse={isPasswordFalse}
          visibility={assetLoaderData?.data?.visibility}
        />
      ) : (<>
      {chooseAssetTemplate(
        tempFlipBook,
        assetLoaderData?.data?.templateName,
        assetLoaderData?.data?.asset_type,
        assetLoaderData?.data,
        setFeatures,
        {
          isContactUsFormEnabled:
            assetLoaderData?.data?.settings?.isContactUsFormEnabled,
          isShareFormEnabled:
            assetLoaderData?.data?.settings?.isShareFormEnabled,
          isSocialSharingButtonEnabled:
            assetLoaderData?.data?.settings?.isSocialSharingButtonEnabled,
          isPoweredByLogoEnabled:
            assetLoaderData?.data?.settings?.isPoweredByLogoEnabled,
        },
        { value: false, url: "" }
      )}
      </>)}

    
      <ShareFeatureDesign />
      <ContactUsFeatureDesign />
      <LeadFeatureDesign />
      <FallbackSocial />
      <LocationTracker
        uid={assetLoaderData?.data?.uid}
        source="assets"
        typeNumber={typeNo}
        assetORCollectionID={assetLoaderData?.data?.asset_id}
      />
    </>
  );
};
export const Loader = ({ params }) => {
  const { assetURL } = params;
  return CollectionAsset({ asset_unique_url: assetURL });
};
