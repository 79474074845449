import React from "react";
import ReactPlayer from "react-player";

export const VideoPlayer = ({ videoURL, autoPlay }) => {
  return (

    <div style={{width:"100%",height:"600px"}}>
    <ReactPlayer playing={true} url={videoURL} controls={true} width={"100%"} height={'100%'}  />
    </div>
  );
};
 