import React from "react";
import { Template2Header } from "../Template2Header";
import { Template2Footer } from "../Template2Footer";
import { Template2PageContent } from "./Template2PageContent";
import { Template2AssetFooter } from "../Template2AssetFooter";

export const Template2Page = ({data,setFeatures, settings, goBack}) => {
  return (
    <>
    <div className="page"> 
      <Template2Header logoURL={data?.logo} setFeatures={setFeatures} settings={settings} goBack={goBack}/>
      <Template2PageContent title={data?.asset_details.title} description={data?.asset_details.description} settings={settings}/>
      <Template2AssetFooter settings={settings}/>
    </div>
    </>
  );
};
