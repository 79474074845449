import React from "react";
import { BsFillChatLeftTextFill, BsShareFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { chooseGuideBookContent } from "../../../../../utils/chooseGuideBookContent";
import { FaDownload } from "react-icons/fa";
import {
  DownloadDocument,
  DownloadImage,
  DownloadThreeD,DownloadAudios
} from "../../../../../utils/functions";

//Icons
import { BiSad } from "react-icons/bi";



//Redux 
import { useDispatch } from "react-redux";
import { updateState } from "../../../../../store/slice/state";


export const Template3DesktopRightSection = ({
  settings,
  displayAsset,
  setFeatures,
}) => {
  const globalDispatch = useDispatch();
  return (
    <>
      <div className="temp3-right-sec">
        <div className="temp3-right-sec__header">
          <div className="header__features-box">
            {settings?.isShareFormEnabled === "Yes" ? (
              <button
                id="email_share_button"
                className="custom-button custom-button__round custom-button--border-white-2 custom-button--w-40 custom-button--transparent"
                onClick={() => {
                  setFeatures((prevState) => ({
                    ...prevState,
                    isShareActive: true,
                  }));
                }}
              >
                <MdEmail className="custom-button--color-white custom-button--w-15" />
              </button>
            ) : null}
            {settings?.isContactUsFormEnabled === "Yes" ? (
              <button
                id="contact_us_button"
                className="custom-button custom-button__round custom-button--border-white-2 custom-button--w-40 custom-button--transparent"
                onClick={() => {
                  setFeatures((prevState) => ({
                    ...prevState,
                    isContactUsActive: true,
                  }));
                }}
              >
                <BsFillChatLeftTextFill className="custom-button--color-white custom-button--w-15" />
              </button>
            ) : null}

            {settings?.isSocialSharingButtonEnabled === "Yes" ? (
              <button
                id="social_share_button"
                className="custom-button custom-button__round custom-button--border-white-2 custom-button--w-40 custom-button--transparent"
                onClick={async () => {
                  if (navigator.share) {
                    navigator.share({
                      title: `${document.title}`,
                      url: window.location.href,
                      text: `${document.title}`,
                    });
                  } else {
                    globalDispatch(updateState({ isSocialShareOpen: true }));
                  }
                }}
              >
                <BsShareFill className="custom-button--color-white custom-button--w-15" />
              </button>
            ) : null}
          </div>
        </div>
        <div className="temp3-right-sec__content">
          <div className="guide-book-title-box">
            <h2 className="guide-book-title-box__title">
              {displayAsset?.title}
            </h2>
            {displayAsset?.identifiedAs.toLowerCase() === "images" ||
            displayAsset?.identifiedAs.toLowerCase() === "documents" ||
            displayAsset?.identifiedAs.toLowerCase() === "3d" ||
            displayAsset?.identifiedAs.toLowerCase() === "audios" ? (
              <>
                {displayAsset?.settings?.allowDownload == "Yes" ? (
                  <FaDownload
                    onClick={() => {
                      if (
                        displayAsset?.identifiedAs.toLowerCase() === "images"
                      ) {
                        DownloadImage(displayAsset?.source);
                      }
                      if (
                        displayAsset?.identifiedAs.toLowerCase() === "documents"
                      ) {
                        DownloadDocument(displayAsset?.source);
                      }
                      if (displayAsset?.identifiedAs.toLowerCase() === "3d") {
                        DownloadThreeD(displayAsset?.source);
                      }
                      if (
                        displayAsset?.identifiedAs.toLowerCase() === "audios"
                      ) {
                        DownloadAudios(displayAsset?.source);
                      }
                    }}
                    className="custom-button__round--primary custom-button--w-20 custom-button"
                  />
                ) : null}
              </>
            ) : null}
          </div>
          <div
            className="guide-book__desc"
            dangerouslySetInnerHTML={{ __html: displayAsset?.description }}
          ></div>
          <div className="guide-book-content-preview">
            {displayAsset==null&&(<p className="no-content">No content has been added <BiSad className="custom-button--w-20 custom-button--color-primary custom-button--transparent custom-button--border-none "/></p>)}
            {chooseGuideBookContent(
              displayAsset?.identifiedAs.toLowerCase(),
              displayAsset?.source
            )}
            
          </div>
        </div>
        
      </div>
    </>
  );
};
