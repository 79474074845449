import React from "react";
import { Template1Footer } from "../Template1Footer";

export const Template1PageContent = ({ title, description ,settings}) => {
  return (
    <>
      <section className=" pages-wrapper">
        <div className=" content-pages-inner-wrapper">
          {/* <h2 className="asset-typo--title">{title}</h2> */}
          <div className="asset-typo--description" dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>
        {/* <Template1Footer settings={settings} /> */}
      </section>
    </>
  );
};    
