import React, { useState, useEffect } from "react";
import { useMasonryLayout, useWindowSize } from "../../../../../utils/hooks";
import { AiOutlineArrowRight, AiOutlinePlayCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { getThumbnailUrl, stripHtmlTags } from "../../../../../utils/functions";
import { BiImage } from "react-icons/bi";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

const ThumbnailCard = ({ item, goToIndividualAsset,fullData }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const isVideo = item.identifiedAs.toLowerCase() === "videos";
  const isImage = item.identifiedAs.toLowerCase() === "images";
  const isProducts = item.identifiedAs.toLowerCase() === "products";
  const textBanner =( item.identifiedAs.toLowerCase() === "documents" || item.identifiedAs.toLowerCase() === "3d")
 

  useEffect(() => {
    const fetchThumbnail = async () => {
      try {
        const url = await getThumbnailUrl(item);
        setThumbnailUrl(url);
      } catch (error) {
        console.error("Error fetching thumbnail:", error);
      }
    };

    fetchThumbnail();
  }, [item]);

  return (
    <Card style={{ width: '18rem', maxHeight:"25rem"}} onClick={() => goToIndividualAsset(item?.identifiedAs.toLowerCase(), fullData,item)} className="temp1-product-tab-content-images-item thumbCard h-auto" >
      <div style={{ position: "relative",backgroundColor: (textBanner && item?.thumbnailImageURL == null) ? fullData?.branding?.accentColors?.primary : ""  }} className="rounded  w-100 d-flex align-items-center">
       {!textBanner ? <LazyLoadImage
          alt=""
          // effect="blur"
          style={{maxHeight:"150px" ,objectFit:(isVideo || isImage || isProducts) ? "cover" : "contain" }}
          src={thumbnailUrl}
          className="rounded  w-100 border-0"
        /> :  item?.thumbnailImageURL == null ? <div className="w-100 rounded text-light font-weight-bold fs-4 d-flex align-items-center justify-content-center m-0 text-break w-100" style={{ backgroundColor: `${fullData?.branding?.accentColors?.primary}`,minHeight:"150px" }}>{item?.title}</div> :<LazyLoadImage
        alt=""
        // effect="blur"
        style={{maxHeight:"150px" ,objectFit:(isVideo || isImage || isProducts) ? "cover" : "contain" }}
        src={thumbnailUrl}
        className="rounded  w-100 border-0"
      />} 
        {isVideo && (
          <AiOutlinePlayCircle
            size={50}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "white",
            }}
          />
        )}
        {/* {isImage && (
          <BiImage
            size={30}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
            }}
          />
        )} */}
             
          
       
      </div>
      <Card.Body>
        <Card.Title>
          {item.title.length > 40 ? `${item.title.substring(0, 40)}...` : item.title}
        </Card.Title>
        <Card.Text className="crop-text-4" style={{fontSize:"0.8rem"}} >
          {stripHtmlTags(item.description)}
        </Card.Text>
      </Card.Body>
      <ListGroup>
        <ListGroup.Item className="fw-bold fs-6 d-flex border-0 justify-content-between">
        <p
            className="m-2 "
            style={{
              // backgroundColor: `${fullData?.branding?.accentColors?.primary}`,
              
              color: "#717274",
    fontSize: ".725rem",
    fontWeight: "500",
    lineHeight: "1.25rem",
            }}
          >{item.identifiedAs.length > 70 ? `${item.identifiedAs.substring(0, 70)}...` : item.identifiedAs} </p>
          <span className=" border-0">
          <AiOutlineArrowRight />
        </span>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

const Template2CollectionContainer = ({fullData, data, selectedTab }) => {
  const { collectionURL } = useParams();
  const navigate = useNavigate();
  const { size } = useWindowSize();

  const { columnWiseData } = useMasonryLayout(
    columnNumber(),
    data[selectedTab]
  );

  function columnNumber() {
    let num;
    if (size?.width < 600) {
      num = 1;
    } else if (size?.width >= 600 && size?.width < 1000) {
      num = 2;
    } else if (size?.width >= 1000) {
      num = 4;
    }
    return num;
  }

  function goToIndividualAsset(contentType, fullData, item) {
    console.log(contentType,"mystry contentType")
    if(contentType == "virtual space"){
      navigate(`/${collectionURL}/virtualspace/${item?._id}`, {
        state: { item,fullData }
      });
  }else{navigate(`/${collectionURL}/${contentType}/${item?._id}`);}}

  return (
    <div className="d-flex flex-wrap gap-4 justify-content-evenly justify-content-lg-start p-5">
    {Object.entries(columnWiseData).flatMap(([columnName, items]) =>
      items.map((item, itemIndex) => (
        <div key={`${columnName}-${itemIndex}`} className="d-flex">
          <ThumbnailCard
            item={item}
            goToIndividualAsset={goToIndividualAsset}
            fullData={fullData}
          />
        </div>
      ))
    )}
  </div>
  );
};

export default Template2CollectionContainer;
