import React, { useState } from "react";
import { Template1Header } from "../Template1Header";
import { Template1Footer } from "../Template1Footer";
import { Template1ProductContent } from "./Template1ProductContent";
import { useProductEnquiry } from "../../../../../utils/hooks";
import { Template1Video } from "../Video1/Template1Video";
import Template1VideoPlayer from "./Template1ProductVideoPlayer";
import Template1ProductModelViewer from "./Template1ProductModelViewer";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const Template1Product = ({data,setFeatures , settings, goBack}) => {
  const [enquiry,setEnquiry] = useState(false);
  const {ProductEnquiryDesign} = useProductEnquiry({apiPayload: {
    request_made_by_user_id: 0,
    request_made_to_exhibitor_id: data?.uid,
    product_id: data?.product[0]?.id,
    enquiry_type: 'product',
    source: 'micropages',
  },isActive:enquiry,setChange:setEnquiry})
  
  return (
    <>
    <div className="page">
    <Template1Header logoURL={data?.logo} setFeatures={setFeatures} settings={settings} goBack={goBack}/>
    <Template1VideoPlayer data={data?.product_videos}/> 
    <Template1ProductModelViewer data={data?.product_models}/>
    <Template1ProductContent productImage={data?.product?.[0]?.product_image} productPrice={data?.product?.[0]?.saleprice} productSKU={data?.product?.[0]?.product_sku} productTitle={data?.product?.[0]?.product_title} productDesc={data?.product?.[0]?.product_description} setEnquiry={setEnquiry} data={data} buyNowURL={data?.product?.[0]?.buynowurl} productModel={data?.product?.[0]?.product_model} currencyCode={data?.product?.[0]?.currency_code} settings={settings}/>
    
    <ProductEnquiryDesign/> 
    
    </div>
    </>
  );
};


