import React, { useEffect } from 'react';
import { AiFillCloseCircle, AiOutlineTwitter } from 'react-icons/ai';
import { BsFacebook, BsLinkedin } from 'react-icons/bs';
import { FaTelegramPlane } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';
import { useSelector } from 'react-redux';


//Redux
import { useDispatch } from "react-redux";
import { updateState } from '../../store/slice/state';


const FallbackSocial = ({ data, change }) => {
  const globalDispatch=useDispatch();
  const { isSocialShareOpen } = useSelector((state) => state.state);
  useEffect(()=>{
    //console.log(isSocialShareOpen);
  },[isSocialShareOpen])
  return (
    <>
    {isSocialShareOpen==true && ( <div className='fallback-wrapper'>
      <div className='fallback-inner-wrapper'>
      <div className="d-flex w-100 justify-content-end align-items-center p-1">
        <AiFillCloseCircle
          className="text-primary"
          size={28}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            globalDispatch(updateState({ isSocialShareOpen: false }));
          }}
        />
      </div>
      <div
        className="d-flex w-100 flex-wrap gap-2 align-items-center justify-content-center pb-2 pt-1 px-1"
        style={{ minHeight: '80px' }}>
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open(
              `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
              '_blank'
            );
            globalDispatch(updateState({ isSocialShareOpen: false }));
          }}>
          <BsFacebook size={20} />
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open(`https://wa.me/?text=${window.location.href}`, '_blank');
            globalDispatch(updateState({ isSocialShareOpen: false }));
          }}>
          <IoLogoWhatsapp size={20} /> 
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open(`http://twitter.com/share?text=${window.location.href}`, '_blank');
            globalDispatch(updateState({ isSocialShareOpen: false }));
          }}>
          <AiOutlineTwitter size={20} />
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open(`https://t.me/share/url?url=${window.location.href}`, '_blank');
            globalDispatch(updateState({ isSocialShareOpen: false }));
          }}>
          <FaTelegramPlane size={20} />
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open(
              `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`,
              '_blank'
            );
            globalDispatch(updateState({ isSocialShareOpen: false }));
          }}>
          <BsLinkedin size={20} />
        </button>
      </div>
      </div>
      </div>)}
    {/* <div>
      <div className="d-flex w-100 justify-content-end align-items-center p-1">
        <AiFillCloseCircle
          className="text-primary"
          size={28}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            globalDispatch(updateState({ isSocialShareOpen: false }));
          }}
        />
      </div>
      <div
        className="d-flex w-100 flex-wrap gap-2 align-items-center justify-content-center pb-2 pt-1 px-1"
        style={{ minHeight: '80px' }}>
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open(
              `https://www.facebook.com/sharer/sharer.php?u=${data?.url}`,
              '_blank'
            );
          }}>
          <BsFacebook size={20} />
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open(`https://wa.me/?text=${data?.url}`, '_blank');
          }}>
          <IoLogoWhatsapp size={20} /> 
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open(`http://twitter.com/share?text=${data?.url}`, '_blank');
          }}>
          <AiOutlineTwitter size={20} />
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open(`https://t.me/share/url?url=${data?.url}`, '_blank');
          }}>
          <FaTelegramPlane size={20} />
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open(
              `https://www.linkedin.com/shareArticle?mini=true&url=${data?.url}`,
              '_blank'
            );
          }}>
          <BsLinkedin size={20} />
        </button>
      </div>
      </div> */}
    </>
  );
};

export default FallbackSocial;
