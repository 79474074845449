import React, { useEffect } from "react";
import
  {
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Route,
  } from "react-router-dom";
import RootLayout from "./RootLayout";
// import Error from './components/errorPages/Error';
// import Error_forbidden from './components/errorPages/Error403';

// import {
//   CollectionRoute,
//   Loader as CollectionLoader,
// } from "./Pages/CollectionRoute";
// import {
//   CollectionRouteAsset,
//   Loader as CollectionRouteAssetLoader,
// } from "./Pages/CollectionRouteAsset";
// import {
//   DirectRouteAsset,
//   Loader as DirectRouteAssetLoader,
// } from "./Pages/DirectRouteAsset";
import { NotFoundError } from "./components/errors/NotFoundError";

import
  {
    CollectionRoutePath,
    Loader as CollectionRoutePathLoader,
  } from "./Pages/CollectionRoutePath";

import
  {
    CollectionRouteAssetPath,
    Loader as CollectionRouteAssetPathLoader,
  } from "./Pages/CollectionRouteAssetPath";
import { DirectRouteAssetPath, Loader as DirectRouteAssetPathLoader } from "./Pages/DirectRouteAssetPath";
export const Api_Key = "GHHrt#3492XWz2DgL0M28Zs$5";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={ <RootLayout /> }>
      <Route
        path=":collectionURL/:contentType/:ID"
        element={ <CollectionRouteAssetPath /> }
        loader={ CollectionRouteAssetPathLoader }
        errorElement={ <NotFoundError /> }
      ></Route>
      <Route
        path="a/:assetURL"
        element={ <DirectRouteAssetPath /> }
        loader={ DirectRouteAssetPathLoader }
        errorElement={ <NotFoundError /> }
      ></Route>
      <Route
        path=":collectionURL"
        element={ <CollectionRoutePath /> }
        loader={ CollectionRoutePathLoader }
        errorElement={ <NotFoundError /> }
      ></Route>
      <Route path="404" element={ <NotFoundError /> } />
      <Route path="*" element={ <NotFoundError /> } />
    </Route>
  ),
  { basename: "/" }
);
function App ()
{
  return <RouterProvider router={ router } />;
}

export default App;
