import React from "react";
import { useEffect, useState } from "react";

const Template1CollectioTabs = ({data,selectedTab,tabsChange}) => {
 //console.log(selectedTab)
  const selectTab = (e) => {

    tabsChange(e.target.innerText);
  }; 
  return (
    <div>
      <div className="sections-wrapper">
        {data?.map((element, index) => {
          return (
            <button
              key={index}
              className={`section-button ${selectedTab === element.sectionTitle ? 'active-tabs-colctn1-temp1' : ''}`}
              data-name={element.sectionTitle}
              onClick={(element) => selectTab(element)}
            >
              {element.sectionTitle}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Template1CollectioTabs;
