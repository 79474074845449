import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { sendResourcesEmail } from "../../store/api";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useState } from "react";
export const ContactUsFeature = ({
  uid,
  changeState,
  countryCodes,
}) => {
  const CONTACT_FORM_LOADER = useRef(null);
  let [phoneNumber, setPhoneNumber] = useState('');
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitSuccessful },
    setError,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      country: "",
      phone: "",
      company: "",
      message: "",
    },
  });

  useEffect(() => {
    reset({
      first_name: "",
      last_name: "",
      email: "",
      country: "",
      phone: "",
      company: "",
      message: "",
    });
  }, [isSubmitSuccessful]);

  const onSubmit = async (data) => {
    if (data.email == "" && phoneNumber == "") {
      setError("email", {
        type: "required",
        message: "Fill either email or phone",
      });
      setError("phone", {
        type: "required",
        message: "Fill either email or phone",
      });
    } 
    else if (phoneNumber !== "" && phoneNumber.length < 3) {
      setError("phone", {
        type: "required",
        message: "Enter valid phone number",
      });
    }
     else {
      CONTACT_FORM_LOADER.current.classList.remove("d-none");
      CONTACT_FORM_LOADER.current.classList.add("d-flex");
      let payload = new FormData();
      payload.append("user_id", uid);
      payload.append("email_address", data?.email);
      payload.append("contact_us_name", data?.first_name);
      payload.append("phone_number", phoneNumber);
      payload.append("mail_instance_id", 13);
      payload.append("message_entered", data?.message);
      payload.append("message_submitted_from_url", window.location.href);
      sendResourcesEmail(payload).then((res) => {
        if (res?.decrypt_data?.Status === "Success") {
          CONTACT_FORM_LOADER.current.classList.remove("d-flex");
          CONTACT_FORM_LOADER.current.classList.add("d-none");
          toast.success(res?.decrypt_data?.message);
          setTimeout(() => {
            changeState((prevState) => ({
              ...prevState,
              isContactUsActive: false,
            }));
          }, 2000);
        } else {
          CONTACT_FORM_LOADER.current.classList.remove("d-flex");
          CONTACT_FORM_LOADER.current.classList.add("d-none");
          toast.error(res?.decrypt_data?.message);
          setTimeout(() => {
            changeState((prevState) => ({
              ...prevState,
              isContactUsActive: false,
            }));
          }, 2000);
        }
      });
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          fontFamily: "Poppins",
          height: "100svh",
          zIndex: 5,
          background: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div
          className="d-flex p-4 border-start border-5 border-primary"
          style={{
            width: "400px",
            height: "100%",
            backgroundColor: "white",
            animation: "0.3s 1 linear slideUp",
          }}
        >
          <div className="d-flex flex-column justify-content-start align-items-start w-100">
            <div className="d-flex justify-content-between align-items-center w-100">
              <h3
                className="text-primary"
                style={{
                  fontWeight: 700,
                  fontSize: "2rem",
                  marginBottom: 0,
                }}
              >
                Contact Us
              </h3>

              <AiOutlineCloseCircle
                size={30}
                style={{ color: "#6610f2", cursor: "pointer" }}
                onClick={() => {
                  changeState((prevState) => ({
                    ...prevState,
                    isContactUsActive: false,
                  }));
                }}
              />
            </div>
            <p className="mb-0" style={{ fontWeight: 500, fontSize: "0.8rem" }}>
              Drop us a message
            </p>
            <hr style={{ width: "100%", height: "5px" }} />
            <form
              id="contact_form"
              className="d-flex flex-column align-items-center w-100 overflow-y p-1 my-2"
              onSubmit={handleSubmit(onSubmit)}
              style={{ overflowY: "auto", height: "100%" }}
            >
              <div className="form-floating mb-2 w-100">
                <div className="form-floating">
                  <input
                    type="text"
                    className={`form-control fs-10 ${
                      errors.first_name ? `is-invalid` : null
                    }`}
                    id="first_name"
                    {...register("first_name", {
                      required: "name is required",
                    })}
                  />
                  <label for="first_name" className="fs-10">
                    Name *
                  </label>
                  {errors?.first_name && (
                    <p className="mb-0 fw-normal fs-11 text-danger mt-1">
                      {errors?.first_name?.message}
                    </p>
                  )}
                </div>
                {/* <div class="form-floating">
                  <input
                    type="text"
                    className={`form-control fs-10 ${
                      errors.last_name ? `is-invalid` : null
                    }`}
                    id="last_name"
                    {...register("last_name", {
                      required: false,
                      pattern: /^[a-zA-Z]*$/,
                    })}
                  />
                  <label for="last_name" className="fs-10">
                    Last Name
                  </label>
                </div> */}
              </div>
              <div className="form-floating mb-2 w-100">
                <input
                  type="text"
                  className={`form-control fs-10 ${
                    errors.email ? `is-invalid` : null
                  }`}
                  id="email"
                  {...register("email", {
                    required: false,
                    pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                  })}
                  onChange={(e) => {
                    if (e.target.value !== "") {
                      clearErrors("email");
                    }
                  }}
                />
                <label for="email" className="fs-10">
                  Email *
                </label>
                {errors?.email ? (
                  <>
                    {errors?.email?.type == "required" ? (
                      <p className="mb-0 fw-normal fs-11 text-danger mt-1">
                        {errors?.email?.message}
                      </p>
                    ) : null}
                    {errors?.email?.type == "pattern" ? (
                      <p className="mb-0 fw-normal fs-11 text-danger mt-1">
                        Enter a valid email id
                      </p>
                    ) : null}
                  </>
                ) : null}
              </div>
              <div className="form-floating mb-2 w-100">
                 <PhoneInput
                          // defaultCountry="US"
                          className={`form-control d-flex fs-10 ${
                            errors.phone ? `is-invalid` : null
                          }`}
                          // {...register("phone", {
                          //   required: false,
                          //   pattern: /^(0|[1-9][0-9]*)$/,
                          //   min: 10,
                          // })}
                         
                          inputStyle={{
                            border: "none",
                            width: "100%",
                            
                          }}
                          onChange={ ( value ) => {setPhoneNumber(value)
                            if (value !== "") {
                              clearErrors("phone");
                            }} }
                          value={phoneNumber}
                        />
                <label for="phone" className="fs-10">
                  Phone Number *
                </label>
                {errors?.phone ? (
                  <>
                   {errors?.phone?.type == "required" ? (
                      <p className="mb-0 fw-normal fs-11 text-danger mt-1">
                        {errors?.phone?.message}
                      </p>
                    ) : null}
                    {errors?.phone?.type == "pattern" ? (
                      <p className="mb-0 fw-normal fs-11 text-danger mt-1">
                        Enter a valid phone no
                      </p>
                    ) : null}
                  </>
                ) : null}
              </div>
              {/* <div className="form-floating mb-2 w-100">
                <input
                  type="text"
                  className={`form-control fs-10 ${
                    errors.company ? `is-invalid` : null
                  }`}
                  id="company"
                  {...register("company", {
                    required: false,
                  })}
                />
                <label for="company" className="fs-10">
                  Company
                </label>
              </div> */}
              <textarea
                className={`form-control fs-10 mb-2 ${
                  errors.message ? `is-invalid` : null
                }`}
                placeholder="Tell us how we can help you *"
                style={{ minHeight: "100px" }}
                id="message"
                {...register("message", {
                  required:
                    "Write us a message so that we can help improve your experience",
                })}
              ></textarea>
              {errors?.message && (
                <p className="mb-0 fw-normal fs-11 text-danger mt-1">
                  {errors?.message?.message}
                </p>
              )}
            </form>
            <button
              className="d-flex align-items-center justify-content-center btn btn-outline-secondary  w-100 gap-3 py-2"
              type="submit"
              form="contact_form"
            >
              <p className="mb-0 fw-semibold fs-10 ">SEND</p>
              <div
                class="spinner-border text-dark d-none"
                ref={CONTACT_FORM_LOADER}
                role="status"
                style={{ width: "30px", height: "30px" }}
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            limit={2}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </div>
      </div>
    </>
  );
};
