import React, { useEffect, useState } from "react";
import { TemplateData } from "../../../../../store/api";
import { useAPIData } from "../../../../../utils/hooks";
import Template1CollectionBanner from "./Template1CollectionBanner";
import Template1CollectioTabs from "./Template1CollectionTabs";
import Template1CollectionContainer from "./Template1CollectionContainer";
import { Template1Header } from "../Template1Header";
import { Template1Footer } from "../Template1Footer";
export const Template1Collection = ({data,setFeatures,settings}) => {
 
  const [selectTabs, setSelectTabs] = useState("");
//console.log(settings,"homepage");
  useEffect(() => {
    const firstTab = data?.data?.sections[0].sectionTitle;
    setSelectTabs(firstTab);

   // document.title = `${data?.branding?.collectionTitle}`
  }, [data]);

  const tabsChange = (e) => {
    setSelectTabs(e);
  };
  return (
    <>
      {typeof data !== "undefined" ? (
        <>
          <Template1Header logoURL={data?.branding?.collectionLogo} settings={settings} setFeatures={setFeatures} goBack={{value:false,url:""}}/>
          <Template1CollectionBanner data={data?.branding} />
          <Template1CollectioTabs
            data={data?.data?.sections}
            tabsChange={tabsChange}
            selectedTab={selectTabs}
          />
          <Template1CollectionContainer
            data={data?.data?.sectionWiseAssets}
            selectedTab={selectTabs}
            fullData={data}
          />
          <Template1Footer settings={settings}/>
    
        </>
      ) : null}
    </>
  );
};

