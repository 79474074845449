import React, { useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";
import { DownloadImage } from "../../../../../utils/functions";
import { Template1Footer } from "../Template1Footer";

import { useLeadFeature } from "../../../../../utils/hooks";



//Redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateState } from "../../../../../store/slice/state";

export const Template1ImageContent = ({
  title,
  description,
  sourceURL,
  allowDownload,
  isCaptureLeadBeforeDownloadingTheAsset,
  data,
  settings
}) => {
  const { isLeadSent, isLeadCapturedForDownloadingAsset } = useSelector((state) => state.state);
  const globalDispatch = useDispatch();
  const [features, setFeatures] = useState({
    isLeadActive: {
      from:
        isLeadSent === false && isCaptureLeadBeforeDownloadingTheAsset === "Yes"
          ? "PAGE"
          : "AFTER-SHARE",
      value:
        isLeadSent === false && isCaptureLeadBeforeDownloadingTheAsset === "Yes"
          ? true
          : false,
    },
  });
  const [downloadAsset, setDownloadAsset] = useState(null);

  const { LeadFeatureDesign } = useLeadFeature({
    exhibitorID: data,
    changeState: setFeatures,
    isActive: downloadAsset,
  });
  const [isDownloadBtnClicked, setIsDownloadBtnClicked] = useState(null);
  let downloadCheckInterval;
  const downloadAssetFn = () => {
    setIsDownloadBtnClicked(true);
    if (isLeadSent === false) {
      setDownloadAsset(features?.isLeadActive?.value);
      downloadCheckInterval = setInterval(checkAssetDownloadFn, 1000);

      if (isCaptureLeadBeforeDownloadingTheAsset === "No") {
        DownloadImage(sourceURL);
      }
    }
    if (isLeadSent === true) {
      DownloadImage(sourceURL);
    }
  };
  function checkAssetDownloadFn() {
    if (isLeadCapturedForDownloadingAsset === true && isDownloadBtnClicked === true) {
      DownloadImage(sourceURL);
      globalDispatch(updateState({ isLeadCapturedForDownloadingAsset: false }));
      clearInterval(downloadCheckInterval);
      setIsDownloadBtnClicked(false);
    }
    clearInterval(downloadCheckInterval);
  }
  useEffect(() => {
    downloadCheckInterval = setInterval(checkAssetDownloadFn, 1000);
  }, [isLeadCapturedForDownloadingAsset]);


  useEffect(() => {
    if (isLeadSent === true) {
      // DownloadImage(sourceURL);
      setDownloadAsset(features?.isLeadActive?.value);
    }
  }, [features?.isLeadActive?.value]);
  return (
    <>
      <section
        className="content--wrapper"
        style={{
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "2rem",
          padding: "1rem 0px 0px 0px",
          minHeight: "90vh",
        }}
      >
        <div className="content--inner-wrapper">
          <div className="temp1-title-box">
            {" "}
            {/* <h2 className="asset-typo--title">{title}</h2>{" "} */}
            {allowDownload == "Yes" ? (
              <FaDownload
                className="custom-button__round--primary custom-button--w-20 custom-button"
                onClick={() => {
                  downloadAssetFn();
                }}
              />
            ) : null}
          </div>
          
          <div className="custom-video-player">
            <img src={sourceURL} width={"100%"} />
          </div>

          <div
            className="asset-typo--description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </div>

      </section>
      <LeadFeatureDesign />
    </>
  );
};
