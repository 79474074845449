
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { store, persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import './css/main.css';
import { ThemeProvider } from './utils/ThemeContext';
const root = ReactDOM.createRoot( document.getElementById( 'root' ) );
root.render(
  <Provider store={ store }>
    <PersistGate loading={ null } persistor={ persistor }>
    <ThemeProvider>
      <App />
      </ThemeProvider>
    </PersistGate>
  </Provider>
);
