import React from "react";
import { Template1Header } from "../Template1Header";
import {Template1Footer} from "../Template1Footer"
import { Template1PageContent } from "./Template1PageContent";
import { Template1AssetHeader } from "../Template1AssetHeader";

import { Template1AssetFooter } from "../Template1AssetFooter";

export const Template1Page = ({data,setFeatures, settings, goBack}) => {
  return (
    <>
    <div className="page">   
    <Template1AssetHeader data={data} logoURL={data?.logo} setFeatures={setFeatures} settings={settings} goBack={goBack} />
      <Template1PageContent title={data?.asset_details.title} description={data?.asset_details.description} settings={settings}/>
      <Template1AssetFooter settings={settings}/>
    </div>
    </>
  );
};
