import React from "react";
import { useEffect, useState } from "react";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { IoIosArrowDropleftCircle } from "react-icons/io";


import leftArrow from "../../../../../assets/left-arrow.png"
import rightArrow from "../../../../../assets/right-arrow.png"
const Template4CollectionTabs = ({ data, selectedTab, tabsChange }) => {
  const selectTab = (e) => {
    tabsChange(e.sectionTitle);
  };
  const leftSectionButton = document.querySelector("#move-left-section");
  const rightSectionButton = document.querySelector("#move-right-section");
  const scrollSection = document.querySelector("#scroll-section");
  let singleSectionWidth = 0;
  if (window.innerWidth < 400) {
    singleSectionWidth = 184;
  } else {
    singleSectionWidth = 184;
  }

  window.addEventListener("resize", () => {
    if (window.innerWidth < 400) {
      singleSectionWidth = 160;
    } else {
      singleSectionWidth = 184;
    }
  });

  leftSectionButton?.addEventListener("click", () => {
    let maximumItemsInView = Math.trunc(
      scrollSection.clientWidth / singleSectionWidth
    );
    if (maximumItemsInView === 0) {
      maximumItemsInView = 1;
    }

    scrollSection.scrollBy({
      top: 0,
      left: -(maximumItemsInView * singleSectionWidth),
      behavior: "smooth",
    });
  });

  rightSectionButton?.addEventListener("click", () => {
    let maximumItemsInView = Math.trunc(
      scrollSection.clientWidth / singleSectionWidth
    );

    if (maximumItemsInView === 0) {
      maximumItemsInView = 1;
    }

    scrollSection.scrollBy({
      top: 0,
      left: maximumItemsInView * singleSectionWidth,
      behavior: "smooth",
    });
  });
  window.addEventListener("resize", function () {
    sliderBtnHideVisible(data?.length);
  });
  function sliderBtnHideVisible(noOfSlides) {
    const scrollSection = document.querySelector("#scroll-section");
    const leftSectionButton = document.querySelector("#move-left-section");
    const rightSectionButton = document.querySelector("#move-right-section");
    if (scrollSection?.offsetWidth > noOfSlides * 204) {
      leftSectionButton.style.visibility = "hidden";
      rightSectionButton.style.visibility = "hidden";
    } else {
      leftSectionButton.style.visibility = "visible";
      rightSectionButton.style.visibility = "visible";
    }
  }
  useEffect(() => {
    window.addEventListener("resize", function () {
      sliderBtnHideVisible(data?.length);
    });
    sliderBtnHideVisible(data?.length);
  }, [data]);
  return (
    <div>
      {/* <div className="temp2-coltn-section">
        {data?.map((element, index) => {
          return (
            <button
              key={index}
              className={`temp2-coltn-section-button ${
                selectedTab === element.sectionTitle
                  ? "temp2-coltn-section-active"
                  : ""
              }`}
              data-name={element.sectionTitle}
              onClick={(element) => selectTab(element)}
            >
              {element.sectionTitle}
            </button>
          );
        })}
      </div> */}
      <div className="categories-sec ">
        <div className="temp4-coltn-slider-section">
     
          <button id="move-left-section">
          
          <img src={leftArrow} alt="previous"/>
          </button>

          <div id="scroll-section">
            {data?.map((element, index) => {
              return ( 
                <div
                  key={index}
                  className={`temp4-coltn-slider-item ${
                    selectedTab === element.sectionTitle
                      ? "temp4-coltn-slider-item-active"
                      : ""
                  }`}
                  data-name={element.sectionTitle}
                >
                   {element.sectionThumbnail !== "" &&
                  element.sectionThumbnail !== null ? (
                    <img
                      src={element.sectionThumbnail}
                      className={``}
                      onClick={() => {
                        selectTab(element);
                      }}
                    />
                  ) : null}
                  {/* <img
                    src={element.sectionThumbnail}
                    className={`${
                      selectedTab === element.sectionTitle
                        ? "temp4-coltn-slider-item-active"
                        : ""
                    }`}
                    onClick={() => {
                      selectTab(element);
                    }}
                  /> */}
                  <h4  onClick={() => {
                        selectTab(element);
                      }}>{element.sectionTitle}</h4>
                </div>
              );
            })}
          </div>
          <button id="move-right-section">
            <img src={rightArrow}/>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Template4CollectionTabs;
