import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BsShareFill, BsFillChatLeftTextFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";

//Redux
import { useDispatch } from "react-redux";
import { updateState } from "../../../store/slice/state";

export const FlipBookHeader = ({ logoURL, settings, setFeatures, goBack, data }) => {
    const navigate = useNavigate();
    const globalDispatch = useDispatch();
    //console.log("currentPage",settings);
    return (
        <>
            <header className="header">
                <div className="header__navigate-box">
                    {typeof goBack.value !== "undefined" && goBack.value ? (
                        <button
                            id="go_back_button"
                            className="custom-button__icon custom-button__icon--variant-no-border custom-button custom-button--border-none custom-button--transparent"
                            onClick={() => {
                                navigate(`/${goBack.url}`);
                            }}
                        >
                            <AiOutlineArrowLeft className="custom-button--w-20" />
                        </button>
                    ) : null}
                    {typeof logoURL !== "undefined" &&
                        logoURL !== null &&
                        logoURL !== "" ? (
                        <div className="header__logo-box">
                            <img className="header__logo" src={logoURL} alt="Logo" />
                        </div>
                    ) : null}
                    <p className="asset-flipbook--title ">{data?.asset_details?.title}</p>

                </div>
                <div className="header__features-box">
                    {settings?.isShareFormEnabled === "Yes" ? (
                        <button
                            id="email_share_button"
                            className="custom-button custom-button__round custom-button--border-primary custom-button--w-30 custom-button--transparent"
                            onClick={() => {
                                setFeatures((prevState) => ({
                                    ...prevState,
                                    isShareActive: true,
                                }));
                            }}
                        >
                            <MdEmail className="custom-button__round--primary custom-button--w-12" />
                        </button>
                    ) : null}
                    {settings?.isContactUsFormEnabled === "Yes" ? (
                        <button
                            id="contact_us_button"
                            className="custom-button custom-button__round custom-button--border-primary custom-button--w-30 custom-button--transparent"
                            onClick={() => {
                                setFeatures((prevState) => ({
                                    ...prevState,
                                    isContactUsActive: true,
                                }));
                            }}
                        >
                            <BsFillChatLeftTextFill className="custom-button__round--primary custom-button--w-12" />
                        </button>
                    ) : null}
                    {settings?.isSocialSharingButtonEnabled === "Yes" ? (
                        <button
                            id="social_share_button"
                            className="custom-button custom-button__round custom-button--border-primary custom-button--w-30 custom-button--transparent"
                            onClick={() => {
                                if (navigator.share) {
                                    navigator.share({
                                        title: `${document.title}`,
                                        url: window.location.href,
                                        text: `${document.title}`,
                                    });
                                } else {
                                    globalDispatch(updateState({ isSocialShareOpen: true }));
                                }
                            }}
                        >
                            <BsShareFill className="custom-button__round--primary custom-button--w-12" />
                        </button>
                    ) : null}
                </div>
            </header>
        </>
    );
};
