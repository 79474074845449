import React, { useEffect, useState } from "react";
import { TemplateData } from "../../../../../store/api";
import { useAPIData } from "../../../../../utils/hooks";
import Template4CollectionBanner from "./Template4CollectionBanner";
import Template4CollectionTabs from "./Template4CollectionTabs";
import Template4CollectionContainer from "./Template4CollectionContainer";
import { Template4Header } from "../Template4Header";
import { Template4Footer } from "../Template4Footer";


export const Template4Collection = ({data,settings,setFeatures}) => {
  
  const [selectTabs, setSelectTabs] = useState("");


  useEffect(() => {
    const firstTab = data?.data?.sections[0].sectionTitle;
    setSelectTabs(firstTab);
    
   // document.title = `${data?.branding?.collectionTitle}`
  }, [data]);

  const tabsChange = (e) => {
    setSelectTabs(e);
  };
  return (
    <>
      {typeof data !== "undefined" ? (
        <>
          <Template4Header logoURL={data?.branding?.collectionLogo} settings={settings} setFeatures={setFeatures} goBack={{value:false,url:""}}/>
          <Template4CollectionBanner data={data?.branding} />
          <Template4CollectionTabs
            data={data?.data?.sections}
            tabsChange={tabsChange}
            selectedTab={selectTabs}
          /> 
          <Template4CollectionContainer
            data={data?.data?.sectionWiseAssets}
            selectedTab={selectTabs}
          />
          <Template4Footer settings={settings}/>
     
        </>
      ) : null}
    </>
  );
};

