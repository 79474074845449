import React from "react";

export const Template1virtualspaceContent = ({ title, description, tour_code }) => {
  // Determine the base URL based on the environment
  const env = process.env.REACT_APP_MODE;
  let baseURL = "";

  if (env === "alpha") {
    baseURL = "https://alpha-space.veespaces.com/360/?tour_code=";
  } else if (env === "uat") {
    baseURL = "https://uat-space.veespaces.com/360/?tour_code=";
  } else if (env === "production") {
    baseURL = "https://space.veespaces.com/360/?tour_code=";
  }

  // Construct the full source URL
  const sourceURL = `${baseURL}${tour_code}`;

  return (
    <>
      <iframe
        width="100%"
        height="100%"
        allowFullScreen
        title="Link Source"
        src={sourceURL}
      ></iframe>
    </>
  );
};
