import React, { useEffect, useState } from "react";
import { DownloadAudios } from "../../../../../utils/functions";
import { FaDownload } from "react-icons/fa";


import { useLeadFeature } from "../../../../../utils/hooks";
//Redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateState } from "../../../../../store/slice/state";


export const Template2AudioContent = ({
  title,
  description,
  sourceURL,
  allowDownload,
  isCaptureLeadBeforeDownloadingTheAsset,
  data,
}) => {
  const { isLeadSent, isLeadCapturedForDownloadingAsset } = useSelector((state) => state.state);
  const globalDispatch = useDispatch();

  const [features, setFeatures] = useState({
    isLeadActive: {
      from:
        isLeadSent === false && isCaptureLeadBeforeDownloadingTheAsset === "Yes"
          ? "PAGE"
          : "AFTER-SHARE",
      value:
        isLeadSent === false && isCaptureLeadBeforeDownloadingTheAsset === "Yes"
          ? true
          : false,
    },
  });
  const [downloadAsset, setDownloadAsset] = useState(null);
  const { LeadFeatureDesign } = useLeadFeature({
    exhibitorID: data,
    changeState: setFeatures,
    isActive: downloadAsset,
  });

  const [isDownloadBtnClicked, setIsDownloadBtnClicked] = useState(null);
  let downloadCheckInterval;
  const downloadAssetFn = () => {
    setIsDownloadBtnClicked(true);
    if (isLeadSent === false) {
      setDownloadAsset(features?.isLeadActive?.value);
      downloadCheckInterval = setInterval(checkAssetDownloadFn, 1000);

      if (isCaptureLeadBeforeDownloadingTheAsset === "No") {
        DownloadAudios(sourceURL);
      }
    }
    if (isLeadSent === true) {
      DownloadAudios(sourceURL);
    }
  };
  function checkAssetDownloadFn() {
    if (isLeadCapturedForDownloadingAsset === true && isDownloadBtnClicked === true) {
      DownloadAudios(sourceURL);
      globalDispatch(updateState({ isLeadCapturedForDownloadingAsset: false }));
      clearInterval(downloadCheckInterval);
      setIsDownloadBtnClicked(false);
    }
    clearInterval(downloadCheckInterval);
  }
  useEffect(() => {
    downloadCheckInterval = setInterval(checkAssetDownloadFn, 1000);
  }, [isLeadCapturedForDownloadingAsset]);

  useEffect(() => {
    if (isLeadSent === true) {
  //    DownloadAudios(sourceURL);
      setDownloadAsset(features?.isLeadActive?.value);
    }
  }, [features?.isLeadActive?.value]);
  return (
    <>
      <section className="content--wrapper audio-content-wrapper">
        <div className="content--inner-wrapper">
          <h2 className="asset-typo--title">
            {title}

            {allowDownload == "Yes" ? (
              <FaDownload
                style={{ marginLeft: "20px" }}
                className="custom-button__round--primary custom-button--w-20 custom-button"
                onClick={() => {
                  downloadAssetFn()
                
                }}
              />
            ) : null}
          </h2>
          <div
            className="asset-typo--description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
          <div className="custom-video-player">
            <audio
              controls
              src={sourceURL}
              width={"100%"}
              controlsList="nodownload"
            />
          </div>
        </div>
      </section>
      <LeadFeatureDesign />
    </> 
  );
};
