import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { stripHtmlTags } from "../../../../../utils/functions";

const Template2CollectionBanner = ({ data }) => {
  const collectionTitle = data?.collectionHeading;
  const collectionDesc = data?.collectionDescription;
  const bannerBgImgURL = data?.collectionBanner?.source;
  const bannerType = data?.collectionBanner?.sourceType;

  return (
    <div className="position-relative w-100  overflow-hidden rounded" style={{ height: '50vh', background:    bannerType !=="video"  ? `${data?.accentColors?.primary}` : "black" }}>
      {bannerBgImgURL && (
        <div className="position-absolute top-0 start-0 w-100 h-100  opacity-75 rounded">
        <video
          className=" w-100 h-100 rounded"
          autoPlay
          muted
          playsInline
          loop
          style={{ objectFit: 'cover',overflow:"hidden",objectPosition:"center top" }}
        >
          <source src={bannerBgImgURL} type="video/mp4" />
          Your browser does not support the video tag.
        </video></div>
      )}
      <div className="position-relative text-center text-white d-flex flex-column justify-content-center align-items-start h-100">
        <h2 className="px-2 mb-2">{collectionTitle}</h2>
        <div
          id="collection-desc"
          className="px-3 text-start"
          style={{fontSize:"1rem"}}
          // dangerouslySetInnerHTML={{ __html: collectionDesc }}
        >{stripHtmlTags(collectionDesc)}</div>
      </div>
    </div>
  );
};

export default Template2CollectionBanner;
