import React from "react";
import { Template1AssetHeader } from "../Template1AssetHeader";
import { Template1Footer } from "../Template1Footer";
import { Template1AudioContent } from "./Template1AudioContent";
import { Template1AssetFooter } from "../Template1AssetFooter";
import { Template1Header } from "../Template1Header";


export const Template1Audio = ({data,setFeatures, settings, goBack}) => {
  return (
    <>    
    <div className="page">
       <Template1AssetHeader data={data} logoURL={data?.logo} setFeatures={setFeatures} settings={settings} goBack={goBack}/>
       <Template1AudioContent title={data?.asset_details.title} description={data?.asset_details.description} sourceURL={data?.asset_details.source_url} allowDownload={data?.settings?.allowDownload}  isCaptureLeadBeforeDownloadingTheAsset={
            data?.settings?.isCaptureLeadBeforeDownloadingTheAsset
          }
          data={data?.exhibitor_id}/>
       <Template1AssetFooter settings={settings}/>
    </div>
    </>
  );
};

