import React from "react";
import { Template1Header } from "../Template1Header";
import { Template1Footer } from "../Template1Footer";
import { Template1AssetFooter } from "../Template1AssetFooter";
import { Template1VideoContent } from "./Template1VideoContent";
import {Template1AssetHeader } from "../Template1AssetHeader";
export const Template1Video = ({ data, setFeatures, settings, goBack }) => {
  return (
    <>
      <div className="page" style={{ height: "auto" }}>
      <Template1AssetHeader
          data={data}
          logoURL={data?.logo}
          setFeatures={setFeatures}
          settings={settings}
          goBack={goBack}
        />
        <Template1VideoContent title={data?.asset_details.title} description={data?.asset_details.description} sourceURL={data?.asset_details.source_url} settings={settings} />

        <Template1AssetFooter settings={settings} />

      </div>
    </>
  );
};


