import React, { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { MdGroup } from "react-icons/md";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getLeadsManagement } from "../../store/api";
import { updateState } from "../../store/slice/state";
import loader from "../../assets/loader.gif";
import "react-toastify/dist/ReactToastify.css";
import { PhoneInput } from "react-international-phone";
export const LeadFeature = ({ exhibitorID, changeState, countryCodes,collectionID}) => {

  const storeDispatch = useDispatch();
  let [phoneNumber, setPhoneNumber] = useState('');
  const defaultFields = [
    {
      id: "first_name",
      name: "First name *",
      tag: "input",
      required: true,
      pattern: /^[a-zA-Z ]*$/,
      type: "text",
      placeholder: "Enter your first name",
    },
    {
      id: "last_name",
      name: "Last name",
      tag: "input",
      required: false,
      pattern: /^[a-zA-Z ]*$/,
      type: "text",
      placeholder: "Enter your last name",
    },
    {
      id: "email_address",
      name: "Email *",
      tag: "input",
      required: true,
      pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
      type: "email",
      placeholder: "Enter your email address",
    },
    // {
    //   id: "phone_code",
    //   name: "Phone code",
    //   tag: "select",
    //   required: false,
    //   pattern: null,
    //   type: "text",
    //   placeholder: "Select country code",
    // },
    {
      id: "phone_no",
      name: "Phone",
      tag: "input",
      required: false,
      pattern: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
      type: "tel",
      placeholder: "Enter your phone no",
    },
    {
      id: "company",
      name: "Company",
      tag: "input",
      required: false,
      pattern: /^[a-zA-Z ]*$/,
      type: "text",
      placeholder: "Enter your company name",
    },
    {
      id: "designation",
      name: "Designation",
      tag: "input",
      required: false,
      pattern: /^[a-zA-Z ]*$/,
      type: "text",
      placeholder: "Enter your designation",
    },
  ];

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email_address: "",
      phone_code: "",
      phone_no: "",
      company: "",
      designation: "",
    },
  });

  useEffect(() => {
    reset({
      first_name: "",
      last_name: "",
      email_address: "",
      phone_code: "",
      phone_no: "",
      company: "",
      designation: "",
    });
  }, [isSubmitSuccessful]);

  let Status = {
    addMore: false,
    leadResponse: {
      state: false,
      message: "",
      severity: "",
    },
    loader: false,
  };

  const leadReducer = (leadState, action) => {
    switch (action.type) {
      case "ADD_MORE_FIELDS": {
        return { ...leadState, addMore: action.value };
      }
      case "LEAD_API_RESPONSE": {
        if (action.state === false) {
          return {
            ...leadState,
            leadResponse: {
              ...[leadState.leadResponse],
              state: action.state,
              message: "",
              severity: "",
            },
          };
        }
        if (action.state === true) {
          return {
            ...leadState,
            leadResponse: {
              ...[leadState.leadResponse],
              state: action.state,
              message: action.message,
              severity: action.severity,
            },
          };
        }
      }
      case "API_LOADER": {
        return {
          ...leadState,
          loader: action.state,
        };
      }
      default: {
        return leadState;
      }
    }
  };

  const [leadState, leadDispatch] = useReducer(leadReducer, Status);

  async function sendLead(data) {
    data.uid=exhibitorID
    data.email_address=[data.email_address]
    data.phone_no=[phoneNumber]
    data.source="micropages"
    let payload = { user: [ data ] };
    leadDispatch({ type: "API_LOADER", state: true });
    await getLeadsManagement(payload)
      .then((res) => {
        if (res?.data?.status.toLowerCase() === "success") {
          toast.success(res?.data.message);
       
        } else {
          toast.error(res?.data.message);
        }
        storeDispatch(updateState({ isLeadSent: true }));
        storeDispatch(updateState({collectionID:collectionID}));
        //for downloading the asset,isLeadCapturedForDownloadingAsset state is used
        storeDispatch(updateState({ isLeadCapturedForDownloadingAsset: true }));
        setTimeout(() => {
          changeState((prevState) => ({
            ...prevState,
            isLeadActive: { from: "AFTER-SHARE", value: false },
            
          }));
         
        }, 3000);
      
        const leadPopContent =
          document.getElementsByClassName("lead-pop-content")[0];
        leadPopContent.style.opacity = "0.4";
        leadPopContent.style.pointerEvents = "none";
        leadDispatch({ type: "API_LOADER", state: false });
      })
      .catch((err) => {
        //console.log(err,"err");
      });
  }

  return (
    <>
      return (
      <>
        <div
          className="lead-pop-overall"
          style={{
            backgroundColor: "aliceblue",
          }}
        >
          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            limit={2}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <div className="lead-pop-content">
            <div className="lead-pop-top-plane">
              <div className="mb-2">
                <MdGroup className="custom-button--w-40 custom-button__round--primary" />
              </div>
              <h3 className="fw-bold fs-4 mb-2 text-primary">
                Drop your contact information
              </h3>
              <h5 className="fw-semilbold fs-11 text-secondary">
                {" "}
                We will share our company, services and products with you
              </h5>
            </div>

            <form className="lead-pop-form" onSubmit={handleSubmit(sendLead)}>
              {defaultFields
                // .slice( 0, `${ leadState.addMore == false ? 5 : 7 }` )
                .map((onefield, index) => {
                  return (
                    <>
                      {onefield?.tag === "select" ? (
                        <div key={index} className="field">
                          <label id="field-title" htmlFor={onefield?.id}>
                            {onefield?.name}
                          </label>
                          <select
                            {...register(onefield?.id, {
                              required: onefield?.required,
                              pattern: onefield?.pattern,
                            })}
                            type={onefield?.type}
                            className="field-input"
                            id={onefield?.id}
                          >
                            <option
                              style={{ fontSize: "0.6rem", fontWeight: "400" }}
                              value=""
                              selected
                            >
                              Select country code
                            </option>
                            {countryCodes !== null
                              ? countryCodes?.map((option, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={option?.phone_country_code}
                                    >
                                      {option?.phone_country_code}
                                    </option>
                                  );
                                })
                              : null}
                          </select>
                        </div>
                      ) : null}

                      {onefield?.tag === "input" && onefield?.id !== "phone_no" ? (
                        <div key={index} className="field">
                          <label id="field-title" htmlFor={onefield?.id}>
                            {onefield?.name}
                          </label>
                          <input
                            placeholder={onefield?.placeholder}
                            {...register(onefield?.id, {
                              required: onefield?.required,
                              pattern: onefield?.pattern,
                            })}
                            type={onefield?.type}
                            className={
                              errors?.[onefield?.id]
                                ? "field-input-err"
                                : "field-input"
                            }
                            id={onefield?.id}
                          ></input>
                          {errors?.[onefield?.id] &&
                            (errors?.[onefield?.id].type === "required" ? (
                              <span
                                style={{
                                  color: "rgb(139, 139, 85)",
                                  fontSize: "0.6rem",
                                }}
                              >
                                This field is required !!
                              </span>
                            ) : errors?.[onefield?.id].type === "pattern" ? (
                              <span
                                style={{
                                  color: "rgb(139, 139, 85)",
                                  fontSize: "0.6rem",
                                }}
                              >
                                Invalid input !!
                              </span>
                            ) : null)}
                        </div>
                      ) : null}
                         {onefield?.id === "phone_no" ? (
                        <div key={index} className="field">
                          <label id="field-title" htmlFor={onefield?.id}>
                            {onefield?.name}
                          </label>
                          {/* <input
                            // placeholder={onefield?.placeholder}
                            {...register(onefield?.id, {
                              required: onefield?.required,
                              pattern: onefield?.pattern,
                            })}
                            type={onefield?.type}
                            className={
                              errors?.[onefield?.id]
                                ? "field-input-err"
                                : "field-input"
                            }
                            id={onefield?.id}
                          ></input> */}
                           <PhoneInput
                          // defaultCountry="US"
                          className={
                            errors?.[onefield?.id]
                              ? "field-input-err p-0"
                              : "field-input p-0"
                          }
                          // {...register("phone", {
                          //   required: false,
                          //   pattern: /^(0|[1-9][0-9]*)$/,
                          //   min: 10,
                          // })}
                         
                          inputStyle={{
                            border: "none",
                            width: "100%",
                            
                            
                          }}
                          onChange={ ( value ) => {setPhoneNumber(value)
                           } }
                          value={phoneNumber}
                        />
                          {errors?.[onefield?.id] &&
                            (errors?.[onefield?.id].type === "required" ? (
                              <span
                                style={{
                                  color: "rgb(139, 139, 85)",
                                  fontSize: "0.6rem",
                                }}
                              >
                                This field is required !!
                              </span>
                            ) : errors?.[onefield?.id].type === "pattern" ? (
                              <span
                                style={{
                                  color: "rgb(139, 139, 85)",
                                  fontSize: "0.6rem",
                                }}
                              >
                                Invalid input !!
                              </span>
                            ) : null)}
                        </div>
                      ) : null}
                    </>
                  );
                })}
              {/* { leadState.addMore === false ? (
                  <button className="custom-button custom-button--primary custom-button--px-1-py-05 custom-button--color-white custom-button--border-none custom-button__box"
                    style={ { width: '100%' } }
                    variant="outlined"
                    onClick={ () =>
                    {
                      leadDispatch( { type: 'ADD_MORE_FIELDS', value: true } );
                    } }>
                    + ADD MORE
                  </button>
                ) : null } */}
              <button
                className="custom-button custom-button--primary custom-button--px-1-py-05 custom-button--color-white custom-button--border-none custom-button__box"
                type="submit"
                variant="filled"
              >
                <p style={{ margin: 0, marginRight: 6 }}>SEND</p>
                {leadState?.loader === true ? (
                  <div class="spinner-border text-light " role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : null}
              </button>
            </form>
          </div>
        </div>
      </>
      );
    </>
  );
};
