import React from "react";
import { useEffect, useState } from "react";

const Template2CollectioTabs = ({data,selectedTab,tabsChange}) => {
 //console.log(selectedTab)
 //console.log("data",data);
  const selectTab = (e) => {

    tabsChange(e.target.innerText);
  };
  return (
    <div>
      <div className="temp2-coltn-section">
        {data?.map((element, index) => {
          return (
            <button
              key={index}
              className={`temp2-coltn-section-button ${selectedTab === element.sectionTitle ? 'temp2-coltn-section-active' : ''}`}
              data-name={element.sectionTitle}
              onClick={(element) => selectTab(element)}
            >
              {element.sectionTitle}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Template2CollectioTabs;
