import React, { useState, useEffect } from "react";

//API
import { TemplateData } from "../store/api";
import { CollectionAuthentication } from "../store/api";

//Router
import { useLoaderData, useParams, Navigate } from "react-router-dom";

//Redux
import { useSelector } from "react-redux";

//Components
import PasswordDialog from "../components/shared/PasswordDialog";
import FallbackSocial from "../components/shared/FallbackSocial";
import LocationTracker from "../utils/LocationTracker";
import { chooseCollectionTemplate } from "../utils/chooseTemplate";

//Hooks
import {
  useContactUsFeature,
  useLeadFeature,
  useShareFeature,
} from "../utils/hooks";

import { emailTemplateInstanceID } from "../utils/constants";

export const CollectionRoutePath = () => {
  const collectionData = useLoaderData();

  let { isLeadSent, collectionID } = useSelector((state) => state.state);
  // console.log(collectionID,"redux");
  const { collectionURL } = useParams();

  const [isPasswordFalse, setIsPasswordFalse] = useState("");
  const [authPass, setAuthPass] = useState(true);

  useEffect(() => {
    if (collectionData?.data?.visibility === "public") {
      setAuthPass(true);
    } else if (collectionData?.data?.visibility === "protected") {
      checkSessionStorageFn();
    } else if (collectionData?.data?.visibility === "private") {
      checkSessionStorageFn();
    }

    setAccentColorsFn();
  }, []);
  const setAccentColorsFn = () => {
    const root = document.documentElement;
    root.style.setProperty(
      "--primary-color",
      collectionData?.data?.branding?.accentColors.primary
    );
    root.style.setProperty(
      "--bs-primary",
      collectionData?.data?.branding?.accentColors.primary
    );
    root.style.setProperty(
      "--bs-primary-rgb",
      collectionData?.data?.branding?.accentColors.primary
    );
    root.style.setProperty(
      "--secondary-color",
      collectionData?.data?.branding?.accentColors.secondary
    );
    if (collectionData?.data?.branding?.pageMetaTitle == null) {
      document.title = `${collectionData?.data?.branding?.collectionTitle}`;
    } else {
      document.title = `${collectionData?.data?.branding?.pageMetaTitle}`;
    }
  };
  // session storage only for type protected and private
  const checkSessionStorageFn = () => {
    let cID = sessionStorage.getItem("authVerify");

    //  cID=JSON.parse(cID)
    //console.log(cID, "sesionStorage");

    let parsedData = JSON.parse(cID);

    if (
      parsedData?.id === collectionData?.data?.collectionID &&
      parsedData?.pass === true
    ) {
      setAuthPass(true);
    } else {
      setAuthPass(false);
    }
  };

  // this if else is for deciding leadCapture form opening
  if (
    collectionID === collectionData?.data?.collectionID &&
    isLeadSent === true
  ) {
    isLeadSent = true;
  } else {
    isLeadSent = false;
  }

  const [features, setFeatures] = useState({
    isContactUsActive: false,
    isShareActive: false,
    isLeadActive: {
      from:
        isLeadSent === false &&
          collectionData?.data?.settings?.isLeadsCaptureFormEnabled === "Yes"
          ? "PAGE"
          : "AFTER-SHARE",
      value:
        isLeadSent === false &&
          collectionData?.data?.settings?.isLeadsCaptureFormEnabled === "Yes"
          ? true
          : false,
    },
    isSocialShareActive: true,
  });
//console.log(collectionURL,'fe');
  const { ShareFeatureDesign } = useShareFeature({
    isActive: features?.isShareActive,
    changeState: setFeatures,
    uid: collectionData?.data?.uid,
    emailConfig: {
      emailInstanceID: emailTemplateInstanceID?.COLLECTION,
      source_id: collectionURL,
      contentType: null,
      collectionURL: collectionURL,
    },
  });
  const { ContactUsFeatureDesign } = useContactUsFeature({
    uid: collectionData?.data?.uid,
    changeState: setFeatures,
    isActive: features?.isContactUsActive,
  });
  const { LeadFeatureDesign } = useLeadFeature({
    exhibitorID: collectionData?.data?.uid,
    changeState: setFeatures,
    isActive: features?.isLeadActive?.value,
    collectionID: collectionData?.data?.collectionID,
  });
  const passwordSubmitFN = (e) => {
    let payload;
    if (collectionData?.data?.visibility === "public") {
      payload = {
        collection_unique_url: collectionURL,
        uid: collectionData?.data?.uid,
        type: collectionData?.data?.visibility,
      };
    } else if (collectionData?.data?.visibility === "protected") {
      payload = {
        collection_unique_url: collectionURL,
        uid: collectionData?.data?.uid,
        type: collectionData?.data?.visibility,
        password: e,
      };
    } else if (collectionData?.data?.visibility === "private") {
      payload = {
        collection_unique_url: collectionURL,
        uid: collectionData?.data?.uid,
        type: collectionData?.data?.visibility,
        email: e,
      };
    }

    CollectionAuthentication(payload)
      .then((res) => {
        if (res?.data?.status === "success") {
          setIsPasswordFalse(false);
          setAuthPass(true);

          let authVerify = {
            id: collectionData?.data?.collectionID,
            pass: true,
          };
          sessionStorage.setItem("authVerify", JSON.stringify(authVerify));
          //  console.log("passed pas", collectionData?.data?.collectionID);
        } else if (res?.data?.status === "failed") {
          setIsPasswordFalse(true);
          setAuthPass(false);
        }
      })
      .catch((err) => {
        // console.log(err);
      });  
  };
  // console.log(features?.isLeadActive?.value,collectionID,collectionData?.data?.collectionID,(!(collectionID===collectionData?.data?.collectionID)),"leadactive");
  // if (collectionData?.data?.status === "failed") {
  //   return <Navigate to="/404" replace={true} />;
  // } else if (collectionData?.data?.visibility === "protected") {
  //   //  checkSessionStorageFn()
  //   // setIsPasswordFalse(true);
  //   //  setAuthPass(false);
  //   // return (
  //   //   <>
  //   //   {!authPass && <PasswordDialog
  //   //       passwordSubmitFN={passwordSubmitFN}
  //   //       isPasswordFalse={isPasswordFalse}
  //   //     />}
  //   //   </>
  //   // );
  // } else if (collectionData?.data?.visibility === "public") {
  //   //setAuthPass(true)
  // }
  if (collectionData?.data?.status === "failed") {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <>
      {/* {!authPass &&   <div class="spinner-border text-primary password-sec-spinner" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>} */}

      {authPass !== true ? (
        <PasswordDialog
          passwordSubmitFN={passwordSubmitFN}
          isPasswordFalse={isPasswordFalse}
          visibility={collectionData?.data?.visibility}
        />
      ) : null}

      {authPass && (
        <>
          {chooseCollectionTemplate(
            collectionData?.data?.templateType,
            collectionData?.data?.templateName,
            collectionData?.data,
            setFeatures,
            {
              isContactUsFormEnabled:
                collectionData?.data?.settings?.isContactUsFormEnabled,
              isShareFormEnabled:
                collectionData?.data?.settings?.isShareFormEnabled,
              isSocialSharingButtonEnabled:
                collectionData?.data?.settings?.isSocialSharingButtonEnabled,
              isPoweredByLogoEnabled: collectionData?.data?.settings?.isPoweredByLogoEnabled,
            }
          )}
          <ShareFeatureDesign />
          <ContactUsFeatureDesign />
          <LeadFeatureDesign />
          <FallbackSocial />
          <LocationTracker
            uid={collectionData?.data?.uid}
            source="collections"
            typeNumber={15}
            assetORCollectionID={collectionData?.data?.collectionID}
          />
        </>
      )}
    </>
  );
};

export function Loader({ params }) {
  const { collectionURL } = params;

  return TemplateData({ collection_unique_url: collectionURL });
}
