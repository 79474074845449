import React from "react";
import { Template3SectionList } from "./Template3SectionList";
import { FaInfoCircle } from "react-icons/fa";
import { IoCloseCircleOutline } from "react-icons/io5";
export const Template3DesktopLeftSection = ({
  titleDesc,
  logo,
  sectionAssets,
  setDisplayAsset,
}) => {
  const descriptionInfoBtn = document.getElementById("temp3-left-sec-info-btn");
//console.log("ee",titleDesc?.collectionDescription);
  const showHideDescription = () => {
    document
      .getElementsByClassName("temp3-left-sec-desc")[0]
      .classList.toggle("temp3-left-sec-desc-show");
  };
  return (
    <>
      <div className="temp3-left-sec">
        <div className="temp3-left-sec__header">
          <div className="temp3-left-sec__header__logo-box">
            {logo !== "" && logo !== null ? (
              <img className="temp3-left-sec__header__logo" src={logo} />
            ) : null}
          </div>
          <div className="temp3-left-sec-title">
            <h6>{titleDesc?.collectionHeading}</h6>

            {titleDesc?.collectionDescription !== "" &&
            titleDesc?.collectionDescription !== null ? (
              <div
                id="temp3-left-sec-info-btn"
                onClick={() => showHideDescription()}
              >
                <FaInfoCircle />
              </div>
            ) : null}
            <div className="temp3-left-sec-desc">
              <IoCloseCircleOutline onClick={() => showHideDescription()} />
              <div
                dangerouslySetInnerHTML={{
                  __html: titleDesc?.collectionDescription,
                }}
              ></div>
            </div>
          </div>
        </div>
        <Template3SectionList
          sectionAssets={sectionAssets}
          setDisplayAsset={setDisplayAsset}
        />
      </div>
    </>
  );
};
