import React from "react";
import { Template1Header } from "../Template1Header";
import { Template1Footer } from "../Template1Footer"

import { Template1AssetFooter } from "../Template1AssetFooter";
import { Template1ImageContent } from "./Template1ImageContent";
import { Template1AssetHeader } from "../Template1AssetHeader";
export const Template1Image = ({ data, setFeatures, settings, goBack }) => {
  return (
    <>
      <div className="page" style={{ height: "auto", gap: "10px" }}>
        <Template1AssetHeader
          data={data}
          logoURL={data?.logo}
          setFeatures={setFeatures}
          settings={settings}
          goBack={goBack}
        />
        
        <Template1ImageContent
          title={data?.asset_details.title}
          description={data?.asset_details.description}
          sourceURL={data?.asset_details.source_url}
          allowDownload={data?.settings?.allowDownload}
          isCaptureLeadBeforeDownloadingTheAsset={
            data?.settings?.isCaptureLeadBeforeDownloadingTheAsset
          }
          data={data?.exhibitor_id}
          settings={settings}
        />
        <Template1AssetFooter settings={settings} />
      </div>
    </>
  );
};
