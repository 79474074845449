import React, { useState } from "react";
import Template4ProductTabs from "./Template4ProductTabs";
import Template4ProductTabContent from "./Template4ProductTabContent";
import { Template4Footer } from "../Template4Footer";

import { Template4AssetFooter } from "../Template4AssetFooter";

const Template4ProductAssets = ({ data ,settings}) => {
  const [selectTabs, setSelectTabs] = useState("");
  const tabsChange = (e) => {
    setSelectTabs(e);
  };
  return (
    <div>
      <Template4ProductTabs
        data={data}
        selectedTab={selectTabs}
        tabsChange={tabsChange}
      />
      <Template4ProductTabContent data={data} selectedTab={selectTabs} />
     
<Template4AssetFooter settings={settings}/>
    </div>
  );
};

export default Template4ProductAssets;
