import React from "react";
import { Template2Header } from "../Template2Header";
import { Template2Footer } from "../Template2Footer";
import { Template2DocumentContent } from "./Template2DocumentContent";
import { Template2AssetFooter } from "../Template2AssetFooter";

export const Template2Document = ({ data , setFeatures , settings, goBack}) => {
 // console.log(data);
  return ( 
    <>
      <div className="page">
        <Template2Header logoURL={data?.logo} setFeatures={setFeatures} settings={settings} goBack={goBack}/>
        <Template2DocumentContent
          title={data?.asset_details.title}
          description={data?.asset_details.description}
          sourceURL={data?.asset_details.source_url}
          allowDownload={data?.settings?.allowDownload}
          isCaptureLeadBeforeDownloadingTheAsset={
            data?.settings?.isCaptureLeadBeforeDownloadingTheAsset
          }
          data={data?.exhibitor_id}
        />
     <Template2AssetFooter settings={settings}/>
      </div>
    </>
  );
};
