import React, { useEffect, useState, useRef } from "react";
import { Document, pdfjs } from "react-pdf";
import HTMLFlipBook from "react-pageflip";
import { ColorRing } from "react-loader-spinner";
//Components
import { Template1Header } from "../webpage/template1/Template1Header";
import { Template1Footer } from "../webpage/template1/Template1Footer";
import { logDOM } from "@testing-library/react";
import { FlipBookHeader } from "./FlipBookHeader";
//import workerSrc from 'pdfjs-dist/build/pdf.worker.entry';
// import pdfjsLib from "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.2.228/build/pdf.min.js";
// console.log(pdfjsLib);
//var fs = require('fs');

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();
//import pdfjs from "@bundled-es-modules/pdfjs-dist/build/pdf";
var pdfjsLib = window["pdfjs-dist/build/pdf"];
//console.log(pdfjsLib);
//pdfjsLib.GlobalWorkerOptions.workerSrc = "./asset/pdf.worker.js";
pdfjs.GlobalWorkerOptions.workerSrc = `https://appveespaces-live.s3.us-east-2.amazonaws.com/assets/images/pdf.worker.min.js`;

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="demoPage" ref={ref}>
      {" "}
      /* ref required */
      <h1>Page Header</h1>
      <p>{props.children}</p>
      <p>Page number: {props.number}</p>
    </div>
  );
});
const FlipBook = ({ data, setFeatures, settings }) => {
  let flipbookRef = useRef();
  //  console.log("fl", data?.asset_details?.source_url);
  const [flipBookImages, setFlipBookImages] = useState([]);
  const [noOfPages, setNoOfPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(2);
  const [loader, setLoader] = useState(true);

  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    // console.log(document.getElementsById("flipbook-wrapper"));
    if (flipBookImages.length == noOfPages) {
     // console.log(flipBookImages.length, noOfPages, "check");
      setLoader(false)
    }

  }, [noOfPages, flipBookImages.length])
  useEffect(() => {
    fetch(data?.asset_details?.source_url)
      .then((res) => res.blob()) // Gets the response and returns it as a blob

      .then((blob) => {
        // reader.readAsDataURL(blob);
        // console.log(blob);

        let reader = new FileReader();
        reader.onload = (e) => {
          const data = atob(e.target.result.replace(/.*base64,/, ""));
          //const data = atob('https://sharespace-live.s3.ap-south-1.amazonaws.com/test/sample.pdf');
          renderPDF(data);
        };
        reader.readAsDataURL(blob);
      });
  }, []);
  async function renderPDF(data) {
    const pdf = await pdfjsLib.getDocument({ data }).promise;
    let imgArr = new Array();
    setNoOfPages(pdf.numPages)

    for (let i = 1; i <= pdf.numPages; i++) {
      const image = document.createElement("img");
      //  document.body.appendChild(image);
      const div = document.createElement("div");
      div.setAttribute("className", "demoPage stf__item");
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 3 });
      const canvas = document.createElement("canvas");
      const canvasContext = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext, viewport }).promise;
      const data = canvas.toDataURL("image/png");
      image.src = data;
      //  console.log(image.src);
      image.style.width = "100%";
      imgArr.push(data);
      // div.appendChild(image);
      // const flipBookWrapper = document.getElementsByClassName("stf__block")[0];
      // flipBookWrapper?.prepend(div);
    }
    setFlipBookImages([...imgArr]);
    //  console.log("arr",img_arr[0]);
  }
  useEffect(() => {
    // var my_awesome_script = document.createElement('script');
    // my_awesome_script.setAttribute('src','https://cdn.jsdelivr.net/npm/pdfjs-dist@2.2.228/build/pdf.min.js');
    // document.body.appendChild(my_awesome_script);

    setTotalPages(flipbookRef?.pageFlip()?.getPageCount())
  }, []);

  const goPreviousPage = () => {
    flipbookRef.pageFlip().flipPrev();

  };
  const goNextPage = () => { flipbookRef.pageFlip().flipNext(); }

  const onPage = (page) => {

    setCurrentPage(page.data)
  };
  return (
    <>
    <FlipBookHeader logoURL={data?.branding?.collectionLogo} settings={settings} setFeatures={setFeatures} goBack={{ value: false, url: "" }} data={data} />
      {/* <Template1Header logoURL={data?.branding?.collectionLogo} settings={settings} setFeatures={setFeatures} goBack={{ value: false, url: "" }} /> */}

      {/* <div className="text-center">
        
        <p dangerouslySetInnerHTML={{ __html: data?.asset_details?.description }}></p>
      </div> */}
      {loader === true ? (<div style={{ width: "100%", height: "100%", textAlign: "center" ,minHeight:"500px"}}>
        <h2>Loading....</h2>
        <h2>Please wait</h2>
        <ColorRing
          visible={loader}
          height="5%"
          width="5%"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
        />
      </div>) : (null)}

      <div id="flipbook-wrapper" className="flipbook">
        <HTMLFlipBook
          width={435}
          height={600}
          // showCover={true}
          //  mobileScrollSupport={true}
          maxShadowOpacity={0.5}
          ref={(elemet) => (flipbookRef = elemet)}
          onFlip={(page) => onPage(page)}
        >
          {flipBookImages.map((item, index) => {
            return (
              <div key={index} className="demoPage" style={{ width: "500px" }}>
                <img src={item} style={{ width: "100%" }} />
              </div>
            );
          })}
        </HTMLFlipBook>
      </div>


      {/* <div className="flipbook-prev-next-btns">
        <button className="btn btn-secondary" onClick={() => goPreviousPage()}>Previous Page</button>
        <span>{currentPage} of {totalPages}</span>
        <button className="btn btn-secondary" onClick={() => goNextPage()}>Next Page</button>
      </div> */}

      <Template1Footer settings={settings} />
       
    </>
  );
};

export default FlipBook;
